import { http } from '@/api'

export const ShopService = {
  listShops() {
    return http.get('/api/shops').then((result) => result.data)
  },
  getShopById(shopId) {
    const shop = http.get(`/api/shop/${shopId}`).then((result) => result.data)

    return shop
  },
  listPaymentMethods() {
    return http.get('/api/payment-methods').then((result) => result.data)
  },

  listPages(shopId) {
    return http.get(`/api/shops/${shopId}/pages`).then((result) => result.data)
  },

  savePage(shopId, page) {
    return http.post(`/api/shops/${shopId}/pages`, page).then((result) => result.data)
  }
}

export default {
  ShopService
}
