<script setup>
  import AppLayout from '@/layout/AppLayout.vue'
  import { useAuthStore } from '@/stores/auth.store.js'
  import AuthLayout from '@/layout/AuthLayout.vue'
  import AppFooter from "@/layout/AppFooter.vue";

  const authStore = useAuthStore()
</script>

<template>
  <AppLayout v-if="authStore.isLoggedIn()"></AppLayout>
  <AuthLayout v-else></AuthLayout>
</template>
