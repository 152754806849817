import { http } from '@/api'

export const ProductService = {
  getProductById(productId) {
    const product = http.get(`/api/products/${productId}`).then((result) => result)

    return product
  },
  async listProducts() {
    return await http.get('/api/products', {
      params: {
        page: 0,
        size: 40,
        sort: 'id',
        direction: 'ASC'
      }
    })
  },
  getPrimaryImage(productId) {
    if (productId) {
      return http.get(`/api/images/product/${productId}/primary`).then((result) => result.data
      )
    }
  },
  async uploadProductCsv(formData) {
    return await http.post('/api/products/import', formData)
        .then((result) => result.data)
  },
  saveProduct(product) {
    const productId = product.id
    console.log(`Updating ${productId} to ${product}`)
    return http.post(`/api/products/${productId}`, product)
        .then((result) => result.data)
  },
  deleteProduct(productId) {
    return http.delete(`/api/products/${productId}`)
  }
}

export default {
  ProductService
}
