// /store/user.js

import { defineStore } from 'pinia'
import axios from 'axios'
import { useLocalStorage } from '@vueuse/core'
import { http } from '@/api.js'
import {usePartyInfoStore} from "@/stores/party.store.js";

const anonymousHttp = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    user: useLocalStorage('user', null),
    jwt: useLocalStorage('jwt', null),
    profile: useLocalStorage('profile', null)
  }),
  persist: true,
  actions: {
    async signIn(username, password, redirect = '/') {
        await anonymousHttp.post('/login', {
            userName: username,
            password: password
        })
            .then(async (res) => {
                console.log(`loging successful ${JSON.stringify(res)}`)
                this.$state.user = JSON.stringify(res.data.user)
                this.$state.jwt = res.data.token

                await http.get('/api/user/profile').then((result) => {
                    this.$state.profile = JSON.stringify(result.data)
                })

                await usePartyInfoStore().getPartyInfo();

                this.router.replace(redirect)
            })
            .catch((err) => {
                console.error(err)
                //               this.toast.add({severity: "error", summary: "unable to login", detail: err.response.data.message})
            })
    },
      async resetPassword(username, redirect = '/') {
          await anonymousHttp.post('/api/account/reset-password', {
              email: username
          })
              .then(() => {
                  this.router.replace(redirect)
              })
              .catch((err) => {
                  this.toast.add({severity: "error", summary: "unable to login", detail: err.response.data.message})
              })
      },
    isLoggedIn() {
      return this.$state.user && this.$state.jwt && this.$state.profile
    },
    signOut() {
      console.log('Logging out')
      this.$state = null
      localStorage.removeItem('user')
      localStorage.removeItem('jwt')
      localStorage.removeItem('profile')
        this.router.go()
    },
    async signUp({companyName, email, password}) {
      return await anonymousHttp.post('/api/account/register-seller', {
        companyName: companyName,
        email: email,
        password: password
      })
          .then((res) => {
            this.$state.user = JSON.stringify(res.data.user)
            this.$state.jwt = res.data.token
            this.router.push('/')

            return true
          })
          .catch((err) => {
            //            this.toast.add({ severity: 'error', summary: 'Please correct error(s)', detail: err.response.data.message })
            return false
          }) || false

    },
    getRoleInfo () {
        const profile = JSON.parse(this.$state.profile)
        const user = JSON.parse(this.$state.user)
        return {
            role: user.role,
            email: profile.email,
            avatar: profile.avatar
        }
    }

  }
})
