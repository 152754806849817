<script setup>
  import { onMounted, ref } from 'vue'
  import {TaskService} from "@/service/taskService.js";
  import DataView from "primevue/dataview";
  import {useAuthStore} from "@/stores/auth.store.js";
  import {formatTimeAgo} from "@vueuse/core";
  import TaskPreview from "@/components/Tasks/TaskPreview.vue";
  import TaskForm from "@/components/TaskForm.vue";
  import {useDialog} from "primevue/usedialog";

  const authStore = useAuthStore()
  const currentUserEmail = authStore.getRoleInfo().email
  const tasks = ref([])

  const taskDialog = useDialog()

  const taskForm = async (task) => {

    const workTaskInfo = await TaskService.getTaskWorkFormByTaskId(task.id);
    console.log("!!!! workTaskInfo = " + workTaskInfo)
    taskDialog.open(TaskForm, {
      data: {
        taskId: task.id,
        data: workTaskInfo.data,
        schema: workTaskInfo.schema.elements
      },
      props: {
        header: task.name,
        style: {
          width: '50vw',
        },
        breakpoints: {
          '960px': '75vw',
          '640px': '90vw'
        },
        resizable: true,
        modal: true
      },
      onClose: (options) => {
        loadTasks()
      }
    })
  }

  const claimTask = (taskId) => {
    TaskService.claimTaskByTaskId(taskId)
        .then(() => loadTasks());
  }

  const loadTasks = () => {
    TaskService.listTasks().then((result) => {
      tasks.value = result.data
    })
  }
  onMounted(() => {
    loadTasks()
  })

  const sortKey = ref('kaltham@example.com');
  const sortOrder = ref(1);
  const sortField = ref('assignee');
  const sortOptions = ref([
    {label: 'Newest', value: '!createTime'},
    {label: 'Oldest', value: 'createTime'},
    {label: 'Updated recently', value: '!lastUpdated'},
    {label: 'Due Date', value: '!dueDate'},
    {label: 'Assignee', value: 'assignee'},
  ]);
  const onSortChange = (event) => {
    const value = event.value.value;
    const sortValue = event.value;

    if (value.indexOf('!') === 0) {
      sortOrder.value = -1;
      sortField.value = value.substring(1, value.length);
      sortKey.value = sortValue;
    }
    else {
      sortOrder.value = 1;
      sortField.value = value;
      sortKey.value = sortValue;
    }
  };
</script>

<template>
  <section class="surface-overlay px-4 lg:px-6 py-4 px-4 lg:px-6 border-top-1 surface-border">
    <div class="border-bottom-1 surface-border">
      <h2 class="mt-0 mb-2 text-900 font-bold text-4xl">Tasks</h2>
      <p class="mt-0 mb-5 text-700 font-normal text-base">tasks and active processes</p>
    </div>
    <section class="flex flex-wrap gap-3 py-6 justify-content-between border-bottom-1 surface-border">
      <div class="overflow-x-scroll">
        <Toolbar>
          <template #start>
            <Button icon="pi pi-plus" class="mr-2" severity="secondary" />
            <Button icon="pi pi-print" class="mr-2" severity="secondary" />
            <Button icon="pi pi-upload" severity="secondary" />
          </template>

          <template #center>
            <IconField iconPosition="left">
              <InputIcon>
                <i class="pi pi-search" />
              </InputIcon>
              <InputText placeholder="Search" />
            </IconField>
          </template>

<!--          <template #end> <SplitButton label="Save" :model="items"></SplitButton></template>-->
        </Toolbar>
        <DataView :value="tasks" :sortOrder="sortOrder" :sortField="sortField">
      <template #header>
        <Dropdown v-model="sortKey" :options="sortOptions" optionLabel="label" placeholder="Sort by .." @change="onSortChange($event)" />
      </template>
      <template #list="slotProps">
        <div class="grid grid-nogutter">
          <div v-for="(item, index) in slotProps.items" :key="index" class="col-12">
            <div class="flex flex-column sm:flex-row sm:align-items-center p-4 gap-3" :class="{ 'border-top-1 surface-border': index !== 0 }">
              <div class="flex flex-column md:align-items-end gap-5">
                <span v-if="item.lastUpdated">Updated {{formatTimeAgo(item.lastUpdated)}}</span>
                <span>Created {{formatTimeAgo(item.createTime)}}</span>
                <span v-if="item.dueDate">Due {{formatTimeAgo(item.lastUpdated)}}</span>
                <span v-if="item.followUpDate">Due {{formatTimeAgo(item.followUpDate)}}</span>

<!--                <img class="block xl:block mx-auto border-round w-full" :src="`https://primefaces.org/cdn/primevue/images/product/${item.image}`" :alt="item.name" />-->
<!--                <Tag :value="item.inventoryStatus" :severity="getSeverity(item)" class="absolute" style="left: 4px; top: 4px"></Tag>-->
              </div>
              <div class="flex flex-column md:flex-row justify-content-between md:align-items-center flex-1 gap-4">
                <div class="flex flex-row md:flex-column justify-content-between align-items-start gap-2">
                  <div>
                    <span class="font-medium text-secondary text-sm">item.buyerParty</span>
                    <div class="text-lg font-medium text-900 mt-2">{{ item.name }}</div>
                    <div class="text-lg font-medium text-900 mt-2">{{ item.caseInstanceId }}</div>
                  </div>
                  <div class="surface-100 p-1" style="border-radius: 30px">
                    <div class="surface-0 flex align-items-center gap-2 justify-content-center py-1 px-2" style="border-radius: 30px; box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)">
                      <span class="text-900 font-medium text-sm">item.buyerParty.rating</span>
                      <i class="pi pi-star-fill text-yellow-500"></i>
                    </div>
                  </div>
                </div>
                <div class="flex flex-column md:align-items-end gap-5">
                  <span class="text-xl font-semibold text-900">{{ item.assignee }}</span>
                  <span class="text-xl font-semibold text-900" v-if="item.owner">Owner {{ item.owner }}</span>
                  <div v-if="item.assignee == currentUserEmail" class="flex flex-row-reverse md:flex-row gap-2">
                    <Button class="p-button-outlined mb-3"  :disabled="item.assignee === currentUserEmail" @click="taskForm(item)">{{item.name}} {{item.assignee}}</Button>
                    <Button icon="pi pi-refresh" label="Delegate" @click="claimTask(item.id)"></Button>
                  </div>
                  <div v-else  class="flex flex-row-reverse md:flex-row gap-2">
                    <Button class="p-button-outlined mb-3"  :disabled="item.assignee === currentUserEmail" @click="taskForm(item)">{{item.name}} {{item.assignee}}</Button>
                    <Button icon="pi pi-plus" label="Claim" @click="claimTask(item.id)" :v-if="item.assignee == currentUserEmail"></Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </DataView>
  </div>
    </section>
  </section>
</template>
