<script setup>
import { ref } from 'vue'
import { formatTimeAgo } from '@vueuse/core'
import {TaskService} from "@/service/taskService.js";
import {FulfillmentService} from "@/service/fulfillmentService.js";

const orders = ref([])
const processes = ref([])

FulfillmentService.listFulfillmentOrders().then((result) => {
  orders.value = result.data
})

TaskService.listProcesses("fulfillment").then((result) => {
  processes.value = result.data.map((p) => {return {label: p.name, command: () => gotoProcessStartPage(p.key)}})
})

</script>
<template>
  <section class="surface-overlay px-4 lg:px-6 py-4 px-4 lg:px-6 border-top-1 surface-border">
    <div class="border-bottom-1 surface-border">
      <h2 class="mt-0 mb-2 text-900 font-bold text-4xl">Fulfillment</h2>
      <p class="mt-0 mb-5 text-700 font-normal text-base">Pick, pack and ship</p>
    </div>
    <TabView>
      <TabPanel header="Orders">
        <section class="flex flex-wrap gap-3 py-6 justify-content-between border-bottom-1 surface-border">
      <div class="overflow-x-scroll">
        <Toolbar class="mb-4">
          <template #start>
            <Button label="New" icon="pi pi-plus" severity="success" class="mr-2" />
            <Button label="Delete" icon="pi pi-trash" severity="danger" />
          </template>
          <template #end>
            <!--            <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />-->
            <!--            <Button label="Export" icon="pi pi-upload" severity="help" />-->
            <SplitButton :model="processes" @click="save" severity="contrast">
            <span class="flex align-items-center font-bold">
                <span>Start Process</span>
            </span>
            </SplitButton>

          </template>
        </Toolbar>
        <DataTable :value="orders" :tabStyle="{ 'min-width': '60rem' }" rowHover dataKey="id" paginator :rows="10" stripedRows>
          <Column style="min-width: 25rem" sortable>
            <template #header>
              <span class="font-semibold text-sm text-color-secondary">Sequence</span>
            </template>
            <template #body="{ data }">
              <RouterLink :to="'/orders/' + data.id">
                <p class="mt-0 mb-2 font-medium text-lg text-color-primary">{{ data.sequence }}</p>
              </RouterLink>
            </template>
          </Column>
          <Column style="min-width: 14rem" sortable>
            <template #header>
              <span class="font-semibold text-sm text-color-secondary">Buyer</span>
            </template>
            <template #body="{ data }">
              <RouterLink :to="'/orders/' + data.id">
                <p class="mt-0 mb-2 font-normal text-base text-color-secondary">{{ data.buyerParty.name }}</p>
              </RouterLink>
            </template>
          </Column>
          <Column sortable>
            <template #header>
              <span class="font-semibold text-sm text-color-secondary">Status</span>
            </template>
            <template #body="{ data }">
              <RouterLink :to="'/orders/' + data.id">
                <p>{{ data.status }}</p>
              </RouterLink>
            </template>
          </Column>
          <Column sortable>
            <template #header>
              <span class="font-semibold text-sm text-color-secondary">Payment</span>
            </template>
            <template #body="{ data }">
              <p class="mt-0 mb-2 font-normal text-base text-color-secondary">{{ data.paymentStatus }}</p>
            </template>
          </Column>
          <Column sortable>
            <template #header>
              <span class="font-semibold text-sm text-color-secondary">Delivery</span>
            </template>
            <template #body="{ data }">
              <p class="mt-0 mb-2 font-normal text-base text-color-secondary">{{ data.deliveryStatus }}</p>
            </template>
          </Column>
          <!--          <Column sortable>-->
          <!--            <template #header>-->
          <!--              <span class="font-semibold text-sm text-color-secondary">Availability</span>-->
          <!--            </template>-->
          <!--            <template #body="{data}">-->
          <!--              <Tag :value="data.availability.totalQty + ' ' + data.availability.status" :severity="(data.availability.totalQty > data.availability.minOnHandQty) ? 'info' : 'severe'" />-->

          <!--            </template>-->
          <!--          </Column>-->
          <Column sortable>
            <template #header>
              <span class="font-semibold text-sm text-color-secondary">Placed On</span>
            </template>
            <template #body="{ data }">
              {{ formatTimeAgo(data.placedOnMillis) }}
            </template>
          </Column>
        </DataTable>
      </div>
    </section>
      </TabPanel>
      <TabPanel header="Deliveries">
      </TabPanel>
      <TabPanel header="Transport">
        <CarrierList></CarrierList>
      </TabPanel>
      <TabPanel header="Warehouses">
      </TabPanel>
    </TabView>
  </section>
</template>
