<script setup>

import {ShopService} from "@/service/shopService.js";
import {useRoute} from "vue-router";
import {ref} from "vue";

const route = useRoute()

const shopId = route.params.shopId

const pages = ref([])

ShopService.listPages(shopId)
    .then((res) => pages.value = res)

const savePage = (shopId, page) => {
  ShopService.savePage(shopId, page)
      .then((res) => pages.value = res)
}

</script>

<template>
  <h1>Shop Details</h1>

  <Accordion :activeIndex="0" expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
    <AccordionTab v-for="page in pages" :key="page.id">
      <template #header>
            <span class="flex align-items-center gap-2 w-full">
              <span class="font-bold white-space-nowrap"><h3>/{{page.path}}</h3>
            </span>
            </span>
      </template>
        <Fieldset :legend="page.title">
          <Editor v-model="page.content" />
          <Button label="Save" @click="savePage(shopId, page)"/>
          <ToggleButton v-model="page.published" onLabel="Published" offLabel="Not Published" />

        </Fieldset>
    </AccordionTab>
  </Accordion>
</template>
