<script setup>
import {computed, defineAsyncComponent, markRaw, onMounted, ref} from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { SalesService } from '@/service/salesService.js'
  import PartyAvatar from "@/components/PartyAvatar.vue";
  import Price from "@/components/Price.vue";
  import {ProductService} from "@/service/productService.js";
  import {formatTimeAgo, useTimeAgo} from "@vueuse/core";
  import InputNumber from "primevue/inputnumber";
  import {TaskService} from "@/service/taskService.js";
  import {useDialog} from "primevue/usedialog";
  import TaskForm from "@/components/TaskForm.vue";
  import {useAuthStore} from "@/stores/auth.store.js";
  import {useHead} from "@unhead/vue";

  const route = useRoute()
  const router = useRouter()

  const orderId = route.params.orderId
  const taskDialog = useDialog()
  const currentUserEmail = useAuthStore().getRoleInfo().email

  const order = ref({
    customer: {},
    deliveryAddress: {},
    buyerPerson: {},
    unitPrice: {
      currencyCode: '',
      amount: 0
    },
  })

  const imageUrlPrefix = import.meta.env.VITE_API_URL + '/api/images/'
  useHead({
    title: () => `Order #${order.value.sequence} for ${order.value.customer?.name}`
  })


  const tasks = ref([])
  const orderHistory = ref({})

  const loadOrderTasks = (orderBusinessKey) => {
    TaskService.getTasksForBusinessKey(orderBusinessKey).then((result) => {
      tasks.value = result.data
      TaskService.getTaskHistoryForBusinessKey(orderBusinessKey).then((result) => {
        orderHistory.value = result.data
      })
    })

}

onMounted(() => {
  SalesService.getOrderById(orderId).then((result) => {
    order.value = result.data
    loadOrderTasks(order.value.businessKey)
    order.value.items.forEach(i => {
      ProductService.getPrimaryImage(i.productId)
          .then((res) => i.productImage = 'data:image/png;base64,' + res)
    })
  })

})

  const taskForm = async (orderId, task) => {
    console.log("!!! taskForm orderId " + orderId)

    const workTaskInfo = await TaskService.getTaskWorkFormByTaskId(task.id);
    console.log("!!!! workTaskInfo = " + workTaskInfo)
    taskDialog.open(TaskForm, {
      data: {
        taskId: task.id,
        data: workTaskInfo.data,
        schema: workTaskInfo.schema.elements
      },
      props: {
        header: task.name,
        style: {
          width: '50vw',
        },
        breakpoints: {
          '960px': '75vw',
          '640px': '90vw'
        },
        resizable: true,
        modal: true
      },
      onClose: (options) => {
        console.log("!!! onClose orderId " + orderId)
        loadOrderTasks(order.value.businessKey)
      }
    })
  }


</script>

<template>
  <div class="surface-ground">
    <div class="surface-card p-5 shadow-2">
      <Button icon="pi pi-arrow-left" outlined @click="router.go(-1)" />
      <div class="flex flex-column lg:flex-row">
        <div class="flex flex-auto flex-column md:flex-row">
          <div class="flex-auto md:pl-5 lg:pr-5 pt-5 md:pt-0 border-right-none lg:border-right-1 surface-border">
            <h2>SO-{{order.sequence}}
              <Tag :value="order.status" class="mr-2 align-items-center justify-content-between" />
              <Tag :value="order.paymentStatus" class="mr-2 align-items-center justify-content-between" />
              <Tag :value="'delivery: ' + order.deliveryStatus" />
            </h2>
            <span>{{order.channel}} {{formatTimeAgo(order.placedOnMillis)}}</span>
            <div class="flex align-items-center justify-content-between mb-3">
              <span class="font-medium text-2xl text-900">{{ order.customer.name }}</span>
              <PartyAvatar :party="order.customer" :person="order.buyerPerson" />
            </div>
            <div class="text-xl text-700 mb-5" v-if="order.calculatedTotal"><b>Total: </b><Price :price="order.calculatedTotal" /></div>
            <div class="flex flex-column">
              <span class="text-xl font-medium mb-3 text-900" v-if="order.deliveryAddress.line1">Delivery Address</span>
              <div class="flex flex-column text-700 mb-3">
                <span class="mb-1">{{order.deliveryAddress.line1}}</span>
                <span class="mb-1">{{order.deliveryAddress.line2}}</span>
                <span class="mb-1">{{order.deliveryAddress.line3}}</span>
                <span>{{order.deliveryAddress.line4}}</span>
                <span></span>
              </div>
              <h3>Items</h3>
              <div class="border-round surface-border border-1">
                <ul class="list-none p-0 m-0" v-for="item in order.items" :key="item.id">
                  <li class="p-3 border-bottom-1 surface-border flex align-items-start sm:align-items-center">

                    <img :src="item.productImage" :alt="item.title" class="w-3rem sm:w-8rem flex-shrink-0 mr-3 shadow-2" />
                    <div class="flex flex-column">
                      <span class="text-700 font-medium text-xl mb-2">{{ item.productTitle }}</span>
                      <span class="text-600 mb-3">{{ item.sku }}</span>
                      <span class="text-900 font-medium">Quantity</span>
                      <InputNumber v-model="item.quantity" :min="item.minOrderQty" :step="item.orderQtyIncrement" />
                    </div>
                    <span class="text-900 font-medium text-lg ml-auto"><Price :price="item.unitPrice" /></span>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
        <div class="flex flex-column border-top-1 lg:border-top-none surface-border pl-0 pt-5 lg:pt-0 lg:pl-5 mt-5 lg:mt-0">
          <Button class="p-button-outlined mb-3">Invoice</Button>
          <Button icon="pi pi-times " label="Cancel" class="p-button-outlined p-button-danger mb-3"></Button>
          <Button class="p-button-outlined mb-3"  v-for="task in tasks" :key="task.id" :disabled="task.assignee === currentUserEmail" @click="taskForm(orderId, task)">{{task.name}} {{task.assignee}}</Button>
        </div>
      </div>
      <div class="flex flex-wrap mt-5 pb-3" v-if="orderHistory">
        <h5>History</h5>
        <ul>
          <li v-for="comment in orderHistory.comments" :key="comment.id">{{comment}}</li>
        </ul>
        <ul>
          <li v-for="entry in orderHistory.taskHistoryList" :key="entry.startTime">{{entry.assignee}} {{formatTimeAgo(entry.startTime)}} {{entry.name}} {{entry.deleteReason}}</li>
        </ul>
      </div>
      <div class="flex flex-wrap mt-5 pb-3">
        <div class="w-full lg:w-6 pl-3">
          <span class="font-medium text-900">Shipping Address</span>
          <div class="flex flex-column text-900 mt-3 mb-5">
            <span class="mb-1">Celeste Slater</span>
            <span class="mb-1">606-3727 Ullamcorper. Roseville NH 11523</span>
            <span>(786) 713-8616</span>
          </div>

          <span class="font-medium text-900">Payment</span>
          <div class="flex align-items-center mt-3">
            <img src="" class="w-4rem mr-3" />
            <div class="flex flex-column">
              <span class="text-900 mb-1">Visa Debit Card</span>
              <span class="text-900 font-medium">**** **** **** 1234</span>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-6 pl-3 lg:pl-0 lg:pr-3 flex align-items-end mt-5 lg:mt-0">
          <ul class="list-none p-0 m-0 w-full">
            <li class="mb-3"><span class="font-medium text-900">Summary</span></li>
            <li class="flex justify-content-between mb-3">
              <span class="text-900">Subtotal</span>
              <span class="text-900 font-medium text-lg">$36.00</span>
            </li>
            <li class="flex justify-content-between mb-3">
              <span class="text-900">Shipping</span>
              <span class="text-900 font-medium text-lg">$5.00</span>
            </li>
            <li class="flex justify-content-between mb-3">
              <span class="text-900">Tax</span>
              <span class="text-900 font-medium text-lg">$4.00</span>
            </li>
            <li class="flex justify-content-between border-top-1 surface-border py-3">
              <span class="text-900 font-medium">Total</span>
              <span class="text-900 font-bold text-lg">$41.00</span>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </div>
</template>
