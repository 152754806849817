import {http} from '@/api'

export const TaskService = {
    listProcesses(module) {
        return http.get('/api/tasks/processes', {params: {module: module}})
    },

    listTasks() {
        return http.get('/api/tasks/active')
    },

    getTasksForBusinessKey(businessKey) {
        return http.get('/api/tasks', {params: {businessKey: businessKey}})
    },

    getTaskHistoryForBusinessKey(businessKey) {
        return http.get(`/api/tasks/history`, {params: {businessKey: businessKey}})
    },

    getTaskWorkFormByTaskId(taskId) {
        const taskWorkForm = http.get(`/api/tasks/workform/${taskId}`).then((result) => result.data)

        return taskWorkForm
    },

    getProcessStartForm(processDefinitionKey, businessKey) {
        return http.get(`/api/tasks/start`, {params: {processDefKey: processDefinitionKey, businessKey: businessKey}})
    },

    startUserProcess(processDefinitionKey, businessKey, input) {
        return http.post(`/api/tasks/start`,
            input,
            {params: {processDefKey: processDefinitionKey, businessKey}})
    },

    claimTaskByTaskId(taskId) {
        return http.post(`/api/tasks/claim/${taskId}`)
    },

    completeTask(taskId, data) {
        return http.post(`/api/tasks/complete/${taskId}`, data)
    },
}

export default {
    TaskService: TaskService
}
