import { http } from '@/api'

class BillingService {
  fetchCustomers() {
    http.get('/api/partnerships')
        .then(response => {
          this.parties = response.data
        })
  }
  fetchTiers() {
    http.get(`/api/partnerships/tiers`)
        .then(response => {
          this.tiers = response.data
        })
  }
  fetchTerritories() {
    http.get(`/api/parties/${this.getOrgId()}/territories`)
        .then(response => {
          this.territories = response.data
        })
  }
  fetchChannels() {
    http.get(`/api/parties/${this.getOrgId()}/channels`)
        .then(response => {
          this.channels = response.data
        })
  }
  fetchInvoices() {
    const invoices = http.get('/api/invoices')
        .then(response => response.data)
      return invoices
  }
  fetchInvoice(invoiceId) {
    const invoice = http.get("/api/invoices/" + invoiceId)
        .then(response => response.data)
      return invoice
  }
  addInvoice(invoice) {
    http.post(`/api/invoices`, invoice)
        .then(response => {
          this.invoices.push(response.data)
          this.$store.dispatch('setNotification', {color: 'success', show: true, text: 'Saved. Add details to Invoice'})
          this.rowSelected('invoices', response.data.id)
        })
    this.addInvoiceDialog = false
  }
  fetchPayments() {
    http.get('/api/payments')
        .then(response => {
          this.payments = response.data
        })
  }
  fetchPaymentMethods() {
    http.get('/api/payment-methods')
        .then(response => {
          this.paymentMethods = response.data
        })
  }
  addPaymentMethod(pm) {
    http.post('/api/payment-methods', pm)
        .then(response => {
          this.paymentMethods.push(response.data)
          this.addPaymentMethodDialog = false
          this.newPaymentMethod = {
            type: ''
          }
//          this.$store.dispatch('setNotification', {color: 'success', show: true, text: 'Payment method added'})
        })
  }


}

export default new BillingService()
