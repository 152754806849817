<script setup>
  import { ref } from 'vue'
  import { useAuthStore } from '@/stores/auth.store.js'
  import { useRoute } from 'vue-router'

  const authStore = useAuthStore()
  const route = useRoute()

  const email = ref(null)

  function resetPassword() {

    authStore.resetPassword(email.value, '/login')
  }
</script>

<template>
  <div class="px-4 py-8 md:px-6 lg:px-8">
    <div class="flex flex-wrap align-items-center justify-content-center">
      <form class="w-full lg:w-6 p-4 lg:p-7 surface-card">
        <h1>Reset Password</h1>
        <fieldset>
          <label for="email" class="block text-900 font-medium mb-2">Email</label>
          <InputText id="email" v-model="email" type="text" placeholder="Email address" class="w-full mb-4"
                     autocomplete="email"/>
          <Button @click="resetPassword" label="Reset Password" icon="pi pi-user"></Button>
        </fieldset>

        <Divider align="center" class="my-6">
          <span class="text-600 font-normal text-sm">OR</span>
        </Divider>
        <div class="mt-6 text-center text-600">
          Don't have an account?
          <router-link to="/register" tabindex="0" class="font-medium text-cyan-300">
            <Button>Create Account</Button>
          </router-link>
        </div>


      </form>
    </div>
  </div>

</template>
