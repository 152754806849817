import { http } from '@/api'

export const PartyService = {
  listMembers() {
    const members = http.get(`/api/parties/team`).then((result) => result)

    return members
  },
  listRoles() {
    const roles = http.get(`/api/parties/roles`).then((result) => result)

    return roles

  }
}

export default {
  PartnerService: PartyService
}
