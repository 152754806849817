<script setup>
  import { onMounted, ref } from 'vue'
  import { CategoryService } from '@/service/categoryService.js'

  const categories = ref([])

  const columns = ref([
    // { field: 'id', header: 'ID' },
    { field: 'fullPath', header: 'Path', sortable: true }
  ])
  const selectedProducts = ref([])
  function openNew() {}

  function confirmDeleteSelected() {}

  onMounted(() => {
    CategoryService.listCategories().then((content) => (categories.value = content))
  })

  // const filters = ref({});
  // const filterMode = ref({ label: 'Lenient', value: 'lenient' });
  // const filterOptions = ref([
  // { label: 'Lenient', value: 'lenient' },
  // { label: 'Strict', value: 'strict' }
  // ])
</script>
<template>
  <section class="surface-overlay px-4 lg:px-6 py-4 px-4 lg:px-6 border-top-1 surface-border">
    <div class="border-bottom-1 surface-border">
      <h2 class="mt-0 mb-2 text-900 font-bold text-4xl">Categories</h2>
      <p class="mt-0 mb-5 text-700 font-normal text-base">manage categories</p>
    </div>
    <section class="flex flex-wrap gap-3 py-6 justify-content-between border-bottom-1 surface-border">
      <div class="overflow-x-scroll">
        <Toolbar class="mb-4">
          <template #start>
            <Button label="New" icon="pi pi-plus" severity="success" class="mr-2" @click="openNew" />
            <Button label="Delete" icon="pi pi-trash" severity="danger" @click="confirmDeleteSelected" :disabled="!selectedProducts || !selectedProducts.length" />
          </template>
          <template #end>
            <FileUpload mode="basic" accept="csv/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />
            <Button label="Export" icon="pi pi-upload" severity="help" @click="exportCSV($event)" />
          </template>
        </Toolbar>
        <DataTable :value="categories" paginator :rows="5" :rowsPerPageOptions="[10, 25, 50]" filterDisplay="row">
          <Column v-for="col of columns" :key="col.field" :field="col.field" :header="col.header" :sortable="col.sortable"></Column>
        </DataTable>
      </div>
    </section>
  </section>
</template>
