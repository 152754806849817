<script setup>
import {onMounted, ref} from 'vue'
import {useAuthStore} from '@/stores/auth.store.js'
import {usePartyInfoStore} from "@/stores/party.store.js";
import {useRouter} from "vue-router";
import SalesOrderList from "@/components/Sales/SalesOrderList.vue";

const router = useRouter()
const authStore = useAuthStore()
const partyInfo = usePartyInfoStore().partyInfo
const profile = authStore.profile

function signOut() {
  authStore.signOut()
}

const menuItems = ref([
  {icon: 'fa fa-tachometer', text: 'Overview', path: '/'},
  {icon: 'fa fa-thumbtack', text: 'Tasks', path: '/tasks'},
  {
    icon: 'fa fa-cart-shopping', text: 'Sales', path: '/sales', name: 'Sales',
    children: [
      {icon: 'fa fa-cart-shopping', text: 'Customers', path: '/customers', name: 'Customers'}
    ]
  },
  {icon: 'fa fa-cart-shopping', text: 'Purchasing', path: '/purchases', name: 'Purchases',
    children: [
      {icon: 'fa fa-cart-shopping', text: 'Vendors', path: '/vendors', name: 'Vendors'}
    ]},
  {
    icon: 'fa fa-barcode', text: 'Products', path: '/products',
    children: [
      {icon: 'fa fa-object-group', text: 'Categories', path: '/categories', name: 'Categories'},
      {icon: 'fa fa-object-group', text: 'Receiving', path: '/receiving', name: 'Receiving'}
    ]
  },
  {icon: 'fa fa-heart', text: 'Offers', path: '/offers'},
  {icon: 'fa fa-money-bills', text: 'Billing', path: '/billing'},
  {icon: 'fa fa-dolly', text: 'Fulfillment', path: '/fulfillment'},
  {icon: 'fa fa-store', text: 'Shops', path: '/shops', name: 'Shops'},
  // { icon: 'receipt', text: 'Transactions', path: '/transaction' },
  // { icon: 'fa-bar-chart', text: 'Reports', path: '/reports' },
  {
    icon: 'fa fa-cog', text: 'Settings', path: '/settings',
    children: [
      {icon: 'fa fa-user', text: 'Profile', path: '/profile', name: 'Profile'},
    ]
  }
])

// onMounted(() => {
//   menuItems.value.push( { icon: 'fa fa-tachometer', text: 'Overview 2', path: '/foo' },)
//   router.addRoute({
//     path: '/foo',
//     name: 'foo-list',
//     component: SalesOrderList,
//     meta: { requiresAuth: true, breadcrumb: 'Sales' }
//   })
// })

</script>

<template>
  <div id="app-sidebar-2"
       class="bg-bluegray-800 h-screen hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 select-none"
       style="width: 280px">
    <div class="flex flex-column h-full">
      <div class="flex align-items-center px-5 bg-bluegray-900 flex-shrink-0" style="height: 60px">
        <router-link to="/"><span class="text-white text-2xl text-left">{{ partyInfo.name }}</span></router-link>
        <!--        <img src="" alt="Image" height="30" />-->
      </div>
      <div class="overflow-y-auto">
        <ul v-for="item in menuItems" :key="item.path" class="list-none p-1 m-0">
          <li class="align-items-center cursor-pointer cursor-pointer hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50">
            <router-link :to="item.path" class="flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50"
                         v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup' }">
              <i class="mr-2" :class="item.icon"></i>
              <span class="font-medium">{{ item.text }}</span>
              <i v-if="item.children" class="pi pi-chevron-down ml-auto"></i>
            </router-link>
            <ul v-for="subitem in item.children" :key="subitem.path"
                class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden">
              <li
                  class="align-items-center cursor-pointer p-3 cursor-pointer hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50">
                <router-link :to="subitem.path" class="flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                                 "
                             v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup'}">
                  <i class="pi pi-chart-line mr-2"></i>
                  <span class="font-medium">{{ subitem.text }}</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="mt-auto">
        <hr class="mb-3 mx-3 border-top-1 border-none border-bluegray-600"/>
        <ul class="list-none p-2 m-0 hidden origin-bottom animation-duration-150 overflow-hidden animation-ease-in-out">
          <li>
            <a v-ripple
               class="flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50 p-ripple">
              <i class="pi pi-question mr-2"></i>
              <span class="font-medium">Help</span>
            </a>
          </li>
          <li>
            <a v-ripple
               class="flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50 p-ripple">
              <i class="pi pi-cog mr-2"></i>
              <span class="font-medium">Switch role {{ authStore.user.roleName }}</span>
            </a>
          </li>
          <li>
            <a @click="signOut" v-ripple
               class="flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50 p-ripple">
              <i class="pi pi-sign-out mr-2"></i>
              <span class="font-medium">Sign Out</span>
            </a>
          </li>
        </ul>
        <a
            v-ripple
            class="m-3 px-3 py-2 flex align-items-center hover:bg-bluegray-900 border-round cursor-pointer text-bluegray-100 hover:text-bluegray-50 p-ripple"
            v-styleclass="{ selector: '@prev', enterClass: 'hidden', enterActiveClass: 'slidedown', leaveToClass: 'hidden', leaveActiveClass: 'slideup' }">
          <!--          <img :src="'data:image/png;base64,' + profile.avatar" class="mr-2" style="width: 28px; height: 28px" />-->
          <span class="font-medium text-white">{{ profile.email }}</span>
          <i class="pi pi-chevron-up ml-auto"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
