<script setup>
  import PowerSearch from '@/layout/PowerSearch.vue'
  import {useAuthStore} from "@/stores/auth.store";
  import {useLayout} from "@/layout/composables/layout";

  const authStore = useAuthStore()
  const {email, role, avatar} = authStore.getRoleInfo()

</script>

<template>
  <div id="toolbar" class="flex justify-content-between align-items-center px-5 surface-section shadow-2 relative lg:static border-bottom-1 surface-border" style="height: 60px">
    <div class="flex">
      <a
        v-ripple
        class="cursor-pointer block lg:hidden text-700 mr-3 mt-1 p-ripple"
        v-styleclass="{ selector: '#app-sidebar-2', enterClass: 'hidden', enterActiveClass: 'fadeinleft', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutleft', hideOnOutsideClick: true }">
        <i class="pi pi-bars text-4xl"></i>
      </a>
      <PowerSearch></PowerSearch>
    </div>
    <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple" v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'fadein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
      <i class="pi pi-ellipsis-v text-2xl"></i>
    </a>
    <ul class="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static">
      <li>
        <RouterLink to="/inbox" class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-duration-150 transition-colors p-ripple">
          <i class="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
          <span class="block lg:hidden font-medium">Inbox</span>
        </RouterLink>
      </li>
      <li>
        <a v-ripple class="flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-duration-150 transition-colors p-ripple">
          <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0" v-badge.danger></i>
          <span class="block lg:hidden font-medium">Notifications</span>
        </a>
      </li>
      <li class="border-top-1 surface-border lg:border-top-none">
        <a v-ripple class="flex p-3 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer transition-duration-150 transition-colors p-ripple">
          <img :src="'data:image/png;base64, ' + avatar" class="mr-3 lg:mr-0" style="width: 32px; height: 32px" />
          <div class="block ml-1">
            <div class="text-900 font-medium">{{ email }}</div>
            <span class="text-600 font-medium text-sm">{{ role }}</span>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>
