<script setup>
import AutoComplete from "primevue/autocomplete";
import {SearchService} from "@/service/searchService.js";
import {ref, watch} from "vue";
import {useRouter} from "vue-router";
import ProductDetail from "/src/components/Catalog/ProductDetail.vue";

const router = useRouter()

const searchTerm = ref()
const suggestions = ref([])

function selected (oldVal, newVal) {
  console.log(`Selected ${newVal}  ${JSON.stringify(oldVal.value.id)}`)
  router.push('/products/' + oldVal.value.id)
}
function search () {
  SearchService.searchProducts(searchTerm.value)
      .then((result) => {
        suggestions.value = result.hits.map((x) => {
          let match = {
            title:  x.document.title,
            id: x.document.id,
            sku: x.document.sku
          }
          return match
        })
        console.log(`Suggested ${suggestions.value} for ${searchTerm.value}`)

      })
}
</script>

<template>
    <AutoComplete v-model="searchTerm" :suggestions="suggestions" @complete="search" @itemSelect="selected">
      <template #option="slotProps">
        <div class="flex">
          <div>{{ slotProps.option.title }}</div>
          <b>{{ slotProps.option.sku }}</b>
        </div>
      </template>
    </AutoComplete>
</template>
