<script setup>
import {onMounted, ref, inject} from 'vue'
import {TaskService} from "@/service/taskService.js";
import {FormKit} from "@formkit/vue";
import '@sfxcode/formkit-primevue/dist/style.css'

const emit = defineEmits(['onClose'])

const dialogRef = inject('dialogRef');

const schema = ref([])
const data = ref({})
const taskId = ref(null)

onMounted(() => {
  schema.value = dialogRef.value.data.schema
  data.value = dialogRef.value.data.data
  taskId.value = dialogRef.value.data.taskId
})

const submitHandler = (taskId, data) => {
  TaskService.completeTask(taskId, data)
      .then((_) => {
        emit('onClose')
        dialogRef.value.close();
      })
}


</script>
<template>
  <div id="taskFormDialog" class="card flex">
          <FormKit
              id="form"
              v-model="data"
              type="form"
              :submit-attrs="{
              inputClass: 'p-button p-component',
            }"
              @submit="submitHandler(taskId, data)"
          >
            <FormKitSchema :schema="schema" :data="data" />
          </FormKit>
  </div>
</template>
