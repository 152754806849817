import { http } from '@/api'

export const CategoryService = {
  listCategories() {
    return http.get('/api/categories').then((res) => res.data.content)
  }
}

export default {
  CategoryService
}
