<script setup>
import {useRouter} from "vue-router";
import {ref} from "vue";
import Menu from "primevue/menu";

const router = useRouter()

const {editToggle, deletable} = defineProps(['editToggle', 'deletable'])
const emit = defineEmits(['edit', 'save', 'delete', 'cancel'])

const hiddenMenu = ref();

const hiddenMenuOpts = ref([
      {label: 'Delete', icon: 'pi pi-times'}
])

const hiddenMenuCallback = (event) => {
  hiddenMenu.value.toggle(event);

}
</script>

<template>
  <Toolbar class="border-none">
    <template #start>
      <Button icon="pi pi-arrow-left mb-2" outlined @click="router.go(-1)" label="Back"/>
    </template>

    <template #end>
      <span v-if="editToggle" >
      <Button label="Edit" @click="$emit('edit')" class="mr-2"/>
      <Button severity="secondary" type="button" icon="pi pi-ellipsis-v" @click="hiddenMenuCallback" aria-haspopup="true" aria-controls="overlay_menu" />
      <Menu  :v-if="hiddenMenuOpts.length > 0" ref="hiddenMenu" id="overlay_menu" :model="hiddenMenuOpts" :popup="true">
        <template #item="{ item, props }">
          <Button v-if="deletable" label="Delete" outlined severity="danger" class="max-w-full" @click="$emit('delete')"/>
        </template>
      </Menu>

      </span>
      <span v-else>
        <Button label="Cancel" outlined class="mr-2" @click="$emit('edit')"/>
        <Button label="Save" @click="$emit('save')"/>
      </span>

    </template>
  </Toolbar>
</template>
