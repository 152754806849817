<script setup>
import {onMounted, ref} from "vue";
import {FormKit} from "@formkit/vue";
import {TaskService} from "@/service/taskService.js";
import {useAuthStore} from "@/stores/auth.store.js";

const authStore = useAuthStore();
const PROCESS_KEY = 'userUpdateProfile'
const profile = ref(JSON.parse(authStore.profile))
const BUSINESS_KEY = ref(profile.value.businessKey)

const schema = ref([])

const data = ref({})
const taskId = ref(null)

onMounted(() => {
  TaskService.getProcessStartForm(PROCESS_KEY, BUSINESS_KEY.value)
      .then((result) => {
        schema.value = result.data.schema.elements
        data.value = profile.value
      })
})

const submitHandler = (data) => {
  TaskService.startUserProcess(PROCESS_KEY, BUSINESS_KEY.value, data)
      .then((result) => taskId.value = result.data)
}


</script>

<template>
  <section class="surface-overlay px-4 lg:px-6 py-4 px-4 lg:px-6 border-top-1 surface-border">
    <div class="border-bottom-1 surface-border">
      <h2 class="mt-0 mb-2 text-900 font-bold text-4xl">Profile</h2>
      <p class="mt-0 mb-5 text-700 font-normal text-base">Your individual profile</p>
    </div>
    <div class="surface-card p-4 shadow-2 border-round" contenteditable="false">
      <div class="grid formgrid p-fluid">
        <div class="field mb-4 col-6">
          <label for="firstName" class="font-medium">First Name</label>
          <InputText id="firstName" type="text" v-model="profile.firstName" />
        </div>
        <div class="field mb-4 col-6">
          <label for="lastName" class="font-medium">Last Name</label>
          <InputText id="lastName" type="text" v-model="profile.lastName" />
        </div>
        <div class="field mb-4 col-6 md:col-6">
          <label for="position" class="font-medium">Position</label>
          <InputText id="position" type="text" v-model="profile.position" />
        </div>
        <div class="field mb-4 col-6 md:col-6">
          <label for="phoneNumber" class="font-medium">phoneNumber</label>
          <InputText id="phoneNumber" type="text" v-model="profile.phoneNumber"/>
        </div>
        <div class="field mb-4 col-12 md:col-6">
          <label for="avatar" class="font-medium">Avatar</label>
          <div class="flex align-items-center">
<!--            <img src="/images/blocks/avatars/circle/avatar-f-4.png" class="mr-4" />-->
            <FileUpload mode="basic" name="avatar" url="./upload.php" accept="image/*" :maxFileSize="1000000" class="p-button-outlined p-button-plain" chooseLabel="Upload Image"></FileUpload>
          </div>
        </div>
        <div class="surface-100 mb-3 col-6" style="height:2px"></div>
        <div class="field mb-4 col-6 md:col-6">
          <label for="email" class="font-medium">Email</label>
          <InputText id="email" v-model="profile.email" />
        </div>
        <div class="field mb-4 col-12 md:col-6">
          <label for="bio" class="font-medium">Country</label>
          <Dropdown v-model="selectedCountry" :options="countries" optionLabel="name" :filter="true" filterBy="name" :showClear="true" placeholder="Select a Country">
            <template #option="slotProps">
              <div class="flex align-items-center">
  <!--                <img src="/images/blocks/flag/flag_placeholder.png" :class="'mr-2 flag flag-' + slotProps.option.code.toLowerCase()" style="width:18px"/>-->
                <div>{{slotProps.option.name}}</div>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="field mb-4 col-12 md:col-6">
          <label for="city" class="font-medium">City</label>
          <InputText id="city" type="text" />
        </div>
        <div class="field mb-4 col-12 md:col-6">
          <label for="state" class="font-medium">State</label>
          <InputText id="state" type="text" />
        </div>
        <div class="surface-100 mb-3 col-12" style="height:2px"></div>
        <div class="field mb-4 col-12">
          <label for="website" class="font-medium">Website</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">www</span>
            <InputText id="website" type="text" />
          </div>
        </div>
        <div class="surface-100 mb-3 col-12" style="height:2px"></div>
        <div class="col-12">
          <Button label="Save Changes" class="w-auto mt-3" @click="submitHandler(profile)"></Button>
        </div>
      </div>
    </div>
  </section>
<!--  <section class="surface-overlay px-4 lg:px-6 py-4 px-4 lg:px-6 border-top-1 surface-border">-->
<!--    <div class="border-bottom-1 surface-border">-->
<!--      <h2 class="mt-0 mb-2 text-900 font-bold text-4xl">Profile</h2>-->
<!--      <p class="mt-0 mb-5 text-700 font-normal text-base">your personal profile & settings</p>-->
<!--    </div>-->
<!--    <div class="surface-ground">-->
<!--      <div class="surface-card p-5 shadow-2">-->
<!--        <div class="flex flex-column lg:flex-row">-->
<!--          <div class="flex flex-auto flex-column md:flex-row">-->
<!--            <FormKit-->
<!--                id="form"-->
<!--                v-model="data"-->
<!--                type="form"-->
<!--                :submit-attrs="{-->
<!--              inputClass: 'p-button p-component',-->
<!--            }"-->
<!--                @submit="submitHandler(data)"-->
<!--            >-->
<!--              <FormKitSchema :schema="schema" :data="data"/></FormKit>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </section>-->
</template>
