import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '@/views/Dashboard.vue'
import ProductList from '@/components/Catalog/ProductList.vue'
import CategoryList from '@/components/Catalog/CategoryList.vue'
import ProductDetail from '@/components/Catalog/ProductDetail.vue'
import { useAuthStore } from '@/stores/auth.store.js'
import RegisterAccount from '@/components/Auth/RegisterAccount.vue'
import Login from '@/components/Auth/Login.vue'
import ShopList from '@/components/Shops/ShopList.vue'
import ShopDetail from '@/components/Shops/ShopDetail.vue'
import InvoiceList from "@/components/Billing/InvoiceList.vue";
import TaskList from "@/components/Tasks/TaskList.vue";
import TaskForm from "@/components/TaskForm.vue";
import PersonalProfile from "@/components/Profile/PersonalProfile.vue";
import PartySettings from "@/components/Party/PartySettings.vue";
import FulfillmentList from "@/components/Fulfillment/FulfillmentList.vue";
import StartProcess from "@/components/Tasks/StartProcess.vue";
import InvoiceDetail from "@/components/Billing/InvoiceDetail.vue";
import ResetPassword from "@/components/Auth/ResetPassword.vue";
import Inbox from "@/components/Inbox.vue";
import SalesOrderList from "@/components/Sales/SalesOrderList.vue";
import SalesOrderDetail from "@/components/Sales/SalesOrderDetail.vue";
import PurchasesOrderList from "@/components/Purchasing/PurchasesOrderList.vue";
import PurchasesOrderDetail from "@/components/Purchasing/PurchasesOrderDetail.vue";
import CustomerList from "@/components/Sales/CustomerList.vue";
import CustomerDetail from "@/components/Sales/CustomerDetail.vue";
import VendorList from "@/components/Purchasing/VendorList.vue";
import VendorDetail from "@/components/Purchasing/VendorDetail.vue";
import AuthLayout from "@/layout/AuthLayout.vue";

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  routes: [
    {
      path: '/register',
      name: 'register-account',
      component: RegisterAccount,
      meta: { requiresAuth: false }
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPassword,
      meta: { requiresAuth: false }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { requiresAuth: false, layout: AuthLayout }
    },
    {
      path: '/',
      name: 'home',
      component: Dashboard,
      meta: { requiresAuth: true, breadcrumb: 'Home' }
    },
    {
      path: '/inbox',
      name: 'inbox',
      component: Inbox,
      meta: { requiresAuth: true, breadcrumb: 'Home' }
    },
    {
      path: '/tasks',
      name: 'tasks',
      component: TaskList,
      meta: { requiresAuth: true, breadcrumb: 'Tasks' }
    },
    {
      path: '/tasks/start/:module/:processKey',
      name: 'tasks-test',
      component: StartProcess,
      props: true,
      meta: { requiresAuth: true, breadcrumb: 'Tasks' }
    },
    {
      path: '/products',
      name: 'products',
      component: ProductList,
      meta: { requiresAuth: true, breadcrumb: 'Products' }
    },
    {
      path: '/products/:productId',
      name: 'product-detail',
      component: ProductDetail,
      props: true,
      meta: { requiresAuth: true, breadcrumb: 'Products' }
    },
    {
      path: '/categories',
      name: 'categories',
      component: CategoryList,
      meta: { requiresAuth: true, breadcrumb: 'Categories' }
    },
    {
      path: '/sales',
      name: 'sales-list',
      component: SalesOrderList,
      meta: { requiresAuth: true, breadcrumb: 'Sales' }
    },
    {
      path: '/sales/:orderId',
      name: 'sales-detail',
      component: SalesOrderDetail,
      props: true,
      meta: { requiresAuth: true, breadcrumb: 'Orders' }
    },
    {
      path: '/purchases',
      name: 'purchase-list',
      component: PurchasesOrderList,
      meta: { requiresAuth: true, breadcrumb: 'Purchases' }
    },
    {
      path: '/purchases/:orderId',
      name: 'purchase-detail',
      component: PurchasesOrderDetail,
      props: true,
      meta: { requiresAuth: true, breadcrumb: 'Orders' }
    },
    {
      path: '/customers',
      name: 'customer-list',
      component: CustomerList,
      meta: { requiresAuth: true, breadcrumb: 'Customers' }
    },
    {
      path: '/customers/:customerId',
      name: 'customer-detail',
      component: CustomerDetail,
      props: true,
      meta: { requiresAuth: true, breadcrumb: 'Customers' }
    },
    {
      path: '/vendors',
      name: 'vendor-list',
      component: VendorList,
      meta: { requiresAuth: true, breadcrumb: 'Vendors' }
    },
    {
      path: '/vendors/:vendorId',
      name: 'vendors-detail',
      component: VendorDetail,
      props: true,
      meta: { requiresAuth: true, breadcrumb: 'Vendors' }
    },
    {
      path: '/shops',
      name: 'shop-list',
      component: ShopList,
      meta: { requiresAuth: true, breadcrumb: 'Shops' }
    },
    {
      path: '/shops/:shopId',
      name: 'shop-detail',
      component: ShopDetail,
      props: true,
      meta: { requiresAuth: true, breadcrumb: 'Shops' }
    },
    {
      path: '/billing',
      name: 'invoice-list',
      component: InvoiceList,
      meta: {requiresAuth: true, breadcrumb: 'Billing'}
    },
    {
      path: '/billing/:invoiceId',
      name: 'invoice-detail',
      component: InvoiceDetail,
      props: true,
      meta: { requiresAuth: true, breadcrumb: 'Billing' }
    },
    {
      path: '/profile',
      name: 'personal-profile',
      component: PersonalProfile,
      meta: {requiresAuth: true, breadcrumb: 'Profile'}
    },
    {
      path: '/settings',
      name: 'party-settings',
      component: PartySettings,
      meta: {requiresAuth: true, breadcrumb: 'Settings'}
    },
    {
      path: '/fulfillment',
      name: 'fulfillment-list',
      component: FulfillmentList,
      meta: {requiresAuth: true, breadcrumb: 'Fulfillment'}
    },
    // {
    //   path: '/fulfillment/:orderId',
    //   name: 'fulfillment-detail',
    //   component: FulfillmentDetail,
    //   props: true,
    //   meta: {requiresAuth: true, breadcrumb: 'Fulfillment'}
    // },


    // {
    //   path: '/billing/invoices/:invoiceId',
    //   name: 'invoice-detail',
    //   component: InvoiceDetail,
    //   props: true,
    //   meta: {requiresAuth: true, breadcrumb: 'Invoices'}
    // },


    /*
        {
          path: '/settings',
          name: 'party-settings',
          component: PartySettings,
          meta: {requiresAuth: true, breadcrumb: 'Settings'}
        },
        {
          path: '/settings/team/:personId',
          name: 'staff-profile',
          component: StaffProfile,
          props: true,
          meta: {requiresAuth: true, breadcrumb: 'Settings'}
        },
        {
          path: '/products/import',
          name: 'product-import',
          component: ProductImport,
          meta: {requiresAuth: true, breadcrumb: 'Import Products'}
        },
        {
          path: '/categories',
          name: 'category-list',
          component: CategoryList,
          meta: {requiresAuth: true, breadcrumb: 'Categories'}
        },
        {
          path: '/categories/:categoryId',
          name: 'category-detail',
          component: CategoryDetail,
          props: true,
          meta: {requiresAuth: true, breadcrumb: 'Categories'}
        },
        {
          path: '/offers',
          name: 'offer-list',
          component: OfferList,
          meta: {requiresAuth: true, breadcrumb: 'Offers'}
        },
        {
          path: '/offers/:offerId',
          name: 'offer-detail',
          component: OfferDetail,
          props: true,
          meta: {requiresAuth: true, breadcrumb: 'Offer'}
        },
        {
          path: '/fulfillment',
          name: 'fulfillment-list',
          component: FulfillmentList,
          meta: {requiresAuth: true, breadcrumb: 'Fulfillment'}
        },
        {
          path: '/fulfillment/:orderId',
          name: 'fulfillment-detail',
          component: FulfillmentDetail,
          props: true,
          meta: {requiresAuth: true, breadcrumb: 'Fulfillment'}
        },
        {
          path: '/rules',
          name: 'rule-list',
          component: RuleList,
          meta: {requiresAuth: true, breadcrumb: 'Rules'}
        }
    */
  ]
})

router.beforeEach((to, from) => {
  const authStore = useAuthStore()
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresAuth && !authStore.isLoggedIn()) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return {
      path: '/login',
      // save the location we were at to come back later
      query: { redirect: to.fullPath }
    }
  }
})
/*
Sentry.init({
  dsn: 'https://63e9783012e95c6a7e723bf6adce5179@o4506594200649728.ingest.sentry.io/4506594202615808',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/]
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
*/

export default router
