import {http} from '@/api'

export const UserService = {
    getLatestNotifications() {
        return http.get('/api/user/notifications')
            .then((result) => result.data)
    },

    viewNotification(notificationId) {
        return http.get(`/api/user/notifications/${notificationId}`)
            .then((result) => result.data)
    },

    markRead(notificationId) {
        return http.delete(`/api/user/notifications/${notificationId}`)
            .then((result) => result.data)
    },
}

export default {
    UserService: UserService
}
