<script setup>

import {onMounted, ref} from "vue";
import Chip from "primevue/chip";
import billingService from "@/service/billingService.js";


const headers = ref([
  {text: 'Invoice#', value: 'sequence'},
  {text: 'Buyer', value: 'buyerParty'},
  {text: 'Order#', value: 'orderSequence'},
  {text: 'Status', value: 'status'},
  {text: 'Payment', value: 'paymentStatus'},
  {text: 'Delivery', value: 'deliveryStatus'}
])

const invoices = ref([])

const addInvoiceDialog = ref(false)

const newInvoice =ref({
  name: '',
  target: '',
  targetType: '',
  validSince: '',
  validUntil: '',
  targetValue: '',
  subject: [
    // PRODUCT, CATEGORY, TOTAL
  ],
  terms: [],
  items: []
})

const parties = ref([])
const tiers = ref([])
const territories = ref([])
const channels = ref([])
const selected = ref({})
const payments = ref([])
const paymentMethods = ref([])
const newPaymentMethod = ref({
  id: '',
  type: '',
  name: '',
  logo: '',
  manual: false

})

const addPaymentMethodDialog = ref(false)

onMounted(() => {
  billingService.fetchInvoices()
      .then((invs) => {
        invoices.value = invs
        console.log(`invoices ${invs}`)
      })
  // billingService.fetchCustomers()
  // billingService.fetchTiers()
  // billingService.fetchTerritories()
  // billingService.fetchChannels()
  // billingService.fetchPayments()
  // billingService.fetchPaymentMethods()

})

</script>


<template>
  <section class="surface-overlay px-4 lg:px-6 py-4 px-4 lg:px-6 border-top-1 surface-border">
    <div class="border-bottom-1 surface-border">
      <h2 class="mt-0 mb-2 text-900 font-bold text-4xl">Invoices</h2>
      <p class="mt-0 mb-5 text-700 font-normal text-base">billing and payments</p>
    </div>
    <section class="flex flex-wrap gap-3 py-6 justify-content-between border-bottom-1 surface-border">
      <div class="overflow-x-scroll">
        <Toolbar class="mb-4">
          <template #start>
            <Button label="New" icon="pi pi-plus" severity="success" class="mr-2" />
            <Button label="Mark Paid" icon="pi pi-trash" severity="info" />
          </template>
        </Toolbar>
        <KeepAlive>
          <DataTable :value="invoices" :tabStyle="{ 'min-width': '60rem' }" rowHover dataKey="id" paginator :rows="10" stripedRows>
            <Column style="min-width: 25rem" sortable>
              <template #header>
                <span class="font-semibold text-sm text-color-secondary">Sequence</span>
              </template>
              <template #body="{ data }">
                <div class="flex align-items-center gap-3">
                  <RouterLink :to="'/billing/' + data.id">
                    <div>
                      <p class="mt-0 mb-2 font-medium text-lg text-color-primary">{{ data.sequence }}</p>
                    </div>
                  </RouterLink>
                </div>
              </template>
            </Column>
            <Column style="min-width: 14rem" sortable>
              <template #header>
                <span class="font-semibold text-sm text-color-secondary">Customer</span>
              </template>
              <template #body="{ data }">
                <RouteLink :to="'/billing/' + data.id">
                  <p class="mt-0 mb-2 font-normal text-base text-color-secondary">{{ data.buyerParty }}</p>
                </RouteLink>
              </template>
            </Column>
            <Column sortable>
              <template #header>
                <span class="font-semibold text-sm text-color-secondary">Order</span>
              </template>
              <template #body="{ data }">
                <RouteLink :to="'/billing/' + data.id">
                  <p>{{ data.orderSequence }}</p> <p class="mt-0 mb-2 font-normal text-base text-color-secondary">{{ data.status }}</p>
                </RouteLink>
              </template>
            </Column>
            <Column sortable>
              <template #header>
                <span class="font-semibold text-sm text-color-secondary">Status</span>
              </template>
              <template #body="{ data }">
                <p class="mt-0 mb-2 font-normal text-base text-color-secondary">{{ data.paymentStatus }}</p>
              </template>
            </Column>
            <Column sortable>
              <template #header>
                <span class="font-semibold text-sm text-color-secondary">Delivery</span>
              </template>
              <template #body="{ data }">
                <p class="mt-0 mb-2 font-normal text-base text-color-secondary">{{ data.deliveryStatus }}</p>
              </template>
            </Column>
<!--            <Column sortable>-->
<!--              <template #header>-->
<!--                <span class="font-semibold text-sm text-color-secondary">updated</span>-->
<!--              </template>-->
<!--              <template #body="{ data }">-->

<!--                <Chip>{{ data.published }}</Chip>-->
<!--              </template>-->
<!--            </Column>-->
          </DataTable>
        </KeepAlive>
      </div>
    </section>
  </section>
</template>

<!--<template>-->
<!--  <v-layout>-->
<!--    <v-flex>-->
<!--      <v-flex absolute right>-->
<!--        <v-btn color="primary" @click.stop="addInvoiceDialog = true">-->
<!--          <v-icon>add</v-icon>-->
<!--          Add Invoice-->
<!--        </v-btn>-->
<!--      </v-flex>-->

<!--      <v-tabs icons-and-text>-->
<!--        <v-tab>Invoices-->
<!--          <v-icon>receipt</v-icon>-->
<!--        </v-tab>-->
<!--        <v-tab>Payments-->
<!--          <v-icon>fa-credit-card</v-icon>-->
<!--        </v-tab>-->
<!--        <v-tab>Payment Methods-->
<!--          <v-badge overlap color="none">-->
<!--            <v-icon slot="badge" small>settings</v-icon>-->
<!--            <v-icon>fa-credit-card</v-icon>-->
<!--          </v-badge>-->
<!--        </v-tab>-->

<!--        <v-tab-item>-->
<!--          <h1>Invoices</h1>-->
<!--          <v-data-table :headers="headers" :items="invoices">-->
<!--            <template slot="items" slot-scope="props">-->
<!--              <tr @click="rowSelected('invoices', props.item)" id="props.item.id">-->
<!--                <td class="text-left">{{ props.item.sequence }}</td>-->
<!--                <td class="text-left">{{ props.item.buyerParty }}</td>-->
<!--                <td class="text-left">{{ props.item.orderSequence }}</td>-->
<!--                <td class="text-left">{{ props.item.status }}</td>-->
<!--                <td class="text-left">{{ props.item.paymentStatus }}</td>-->
<!--                <td class="text-left">{{ props.item.deliveryStatus }}</td>-->
<!--              </tr>-->
<!--            </template>-->
<!--          </v-data-table>-->
<!--        </v-tab-item>-->

<!--        <v-tab-item>-->
<!--          <h1>Payments</h1>-->
<!--          <v-data-table :headers="headers" :items="payments">-->
<!--            <template slot="items" slot-scope="props">-->
<!--              <tr @click="rowSelected('payments', props.item)" id="props.item.id">-->
<!--                <td>{{ props.item.id }}</td>-->
<!--                <td class="text-left">{{ props.item.id }}</td>-->
<!--                <td class="text-left">{{ props.item.sequence }}</td>-->
<!--                <td>-->
<!--                  <v-icon color="primary">check_circle</v-icon>-->
<!--                </td>-->
<!--              </tr>-->
<!--            </template>-->
<!--          </v-data-table>-->

<!--        </v-tab-item>-->

<!--        <v-tab-item>-->
<!--          <h1>Payment Methods</h1>-->

<!--          <v-layout column>-->
<!--            <v-toolbar flat>-->
<!--              <v-spacer></v-spacer>-->
<!--              <v-toolbar-items>-->
<!--                <v-btn flat color="primary" @click="addPaymentMethodDialog = true">-->
<!--                  <v-icon>add</v-icon>-->
<!--                  Add Payment Method-->
<!--                </v-btn>-->
<!--              </v-toolbar-items>-->
<!--            </v-toolbar>-->

<!--            <v-layout>-->
<!--              <v-flex md v-for="pm in paymentMethods" :key="pm.id">-->
<!--                <v-card>-->
<!--                  <v-card-title><h4>{{ pm.type }}</h4></v-card-title>-->
<!--                  <v-divider></v-divider>-->
<!--                  <v-list dense>-->
<!--                    <v-list-item v-if="pm.name != null">-->
<!--                      <v-list-item-content>name:</v-list-item-content>-->
<!--                      <v-list-item-content class="align-end">{{ pm.name }}</v-list-item-content>-->
<!--                    </v-list-item>-->
<!--                    <v-list-item v-if="pm.description != null">-->
<!--                      <v-list-item-content>description:</v-list-item-content>-->
<!--                      <v-list-item-content class="align-end">{{ pm.description }}</v-list-item-content>-->
<!--                    </v-list-item>-->
<!--                    <v-list-item>-->
<!--                      <v-list-item-content>target:</v-list-item-content>-->
<!--                      <v-list-item-content class="align-end">{{ pm.target }}</v-list-item-content>-->
<!--                    </v-list-item>-->
<!--                    <v-list-item>-->
<!--                      <v-list-item-content>manual?:</v-list-item-content>-->
<!--                      <v-list-item-content class="align-end">{{ pm.isManual }}</v-list-item-content>-->
<!--                    </v-list-item>-->
<!--                    <v-list-item>-->
<!--                      <v-list-item-content>target:</v-list-item-content>-->
<!--                      <v-list-item-content class="align-end">{{ pm.target }}</v-list-item-content>-->
<!--                    </v-list-item>-->

<!--                  </v-list>-->
<!--                </v-card>-->
<!--              </v-flex>-->
<!--            </v-layout>-->
<!--          </v-layout>-->

<!--        </v-tab-item>-->

<!--      </v-tabs>-->

<!--      <v-dialog v-model="addInvoiceDialog" fullscreen transition="dialog-bottom-transition" :overlay="false">-->
<!--        <v-card>-->
<!--          <v-toolbar dark>-->
<!--            <v-btn icon @click.native="addInvoiceDialog = false" dark>-->
<!--              <v-icon>close</v-icon>-->
<!--            </v-btn>-->
<!--            <v-toolbar-title>Add Invoice</v-toolbar-title>-->
<!--            <v-spacer></v-spacer>-->
<!--            <v-toolbar-items>-->
<!--              <v-btn dark flat @click.native="addInvoice(newInvoice)">Save</v-btn>-->
<!--            </v-toolbar-items>-->
<!--          </v-toolbar>-->
<!--          <v-card-text>-->
<!--            <v-flex>-->
<!--              <v-text-field label="text" required v-model="newInvoice.name" hint="brief description of the Invoice"-->
<!--                            placeholder="20% off all .."></v-text-field>-->
<!--              <v-select label="target" required-->
<!--                        :items='["PublicInvoice", "PartyInvoice", "TierInvoice", "ChannelInvoice", "TerritoryInvoice"]'-->
<!--                        v-model="newInvoice.target" @input="selected = newInvoice.target"></v-select>-->

<!--              <v-select v-if="selected == 'PartyInvoice'" label="party" required :items="parties"-->
<!--                        v-model="newInvoice.targetValue"-->
<!--                        hint="the company to make the Invoice to" loading="fetching parties" @focus="selected = ''"-->
<!--                        item-value="id" item-text="name"-->
<!--              ></v-select>-->

<!--              <v-select v-if="selected == 'TierInvoice'" label="tier" required :items="tiers"-->
<!--                        v-model="newInvoice.targetValue"-->
<!--                        :hint="`${tiers.find(x => x.id === newInvoice.targetValue).description}`" persistent-hint-->
<!--                        loading="fetching tier" @focus="selected = ''"-->
<!--                        item-value="id" item-text="name"></v-select>-->

<!--              <v-select v-if="selected == 'TerritoryInvoice'" label="territory" required :items="territories"-->
<!--                        v-model="newInvoice.targetValue"-->
<!--                        hint="the territory to make the Invoice to" loading="fetching territories"-->
<!--                        @focus="selected = ''"-->
<!--                        item-value="id" item-text="name"></v-select>-->

<!--              <v-select v-if="selected == 'ChannelInvoice'" label="channel" required :items="channels"-->
<!--                        v-model="newInvoice.targetValue"-->
<!--                        hint="the channel to make the Invoice to" loading="fetching channels" @focus="selected = ''"-->
<!--                        item-value="id" item-text="name"></v-select>-->


<!--              <v-text-field label="valid from" v-model="newInvoice.validSince"></v-text-field>-->
<!--              <v-text-field label="valid until" v-model="newInvoice.validUntil"></v-text-field>-->
<!--            </v-flex>-->
<!--          </v-card-text>-->
<!--          <v-card-actions>-->
<!--            <v-btn flat @click.stop="addInvoiceDialog = false">Cancel</v-btn>-->
<!--            <v-btn color="primary" flat @click.stop="addInvoice(newInvoice)">Save</v-btn>-->

<!--          </v-card-actions>-->
<!--        </v-card>-->
<!--      </v-dialog>-->

<!--    </v-flex>-->
<!--    <v-dialog v-model="addPaymentMethodDialog" fullscreen transition="dialog-bottom-transition" :overlay="false">-->
<!--      <v-card>-->
<!--        <v-toolbar dark>-->
<!--          <v-btn icon @click.native="addPaymentDialog = false" dark>-->
<!--            <v-icon>close</v-icon>-->
<!--          </v-btn>-->
<!--          <v-toolbar-title>Add Payment Method</v-toolbar-title>-->
<!--          <v-spacer></v-spacer>-->
<!--          <v-toolbar-items>-->
<!--            <v-btn dark flat @click.native="addPaymentMethod(newPaymentMethod)">Save</v-btn>-->
<!--          </v-toolbar-items>-->
<!--        </v-toolbar>-->
<!--        <v-card-text>-->
<!--          <v-flex>-->
<!--            <v-text-field label="type" required v-model="newPaymentMethod.type"></v-text-field>-->
<!--            <v-select label="type" required :items="['ACH', 'Cash', 'Check', 'CreditCard', 'Wire']"-->
<!--                      v-model="newPaymentMethod.type" item-text="type" item-value="type"></v-select>-->
<!--            &lt;!&ndash;          <v-text-field label="contact email" :v-model="newPaymentMethod.contact.email"></v-text-field>&ndash;&gt;-->
<!--          </v-flex>-->
<!--        </v-card-text>-->
<!--        <v-card-actions>-->
<!--          <v-btn flat @click.stop="addPaymentMethodDialog = false">Cancel</v-btn>-->
<!--          <v-btn color="primary" flat @click.stop="addPaymentMethod(newPaymentMethod)">Save</v-btn>-->

<!--        </v-card-actions>-->
<!--      </v-card>-->
<!--    </v-dialog>-->

<!--  </v-layout>-->


<!--</template>-->

