<script setup>
  import { onMounted, ref } from 'vue'
  import { PartnershipService } from '@/service/partnershipService.js'

  const vendors = ref([])

  onMounted(() => {
    PartnershipService.listVendors().then((result) => {
      vendors.value = result.data
    })
  })
</script>
<template>
  <section class="surface-overlay px-4 lg:px-6 py-4 px-4 lg:px-6 border-top-1 surface-border">
    <div class="border-bottom-1 surface-border">
      <h2 class="mt-0 mb-2 text-900 font-bold text-4xl">Vendors</h2>
      <p class="mt-0 mb-5 text-700 font-normal text-base">vendors, suppliers</p>
    </div>
    <TabView>
      <TabPanel header="Parties">
      <section class="flex flex-wrap gap-3 py-6 justify-content-between border-bottom-1 surface-border">
      <div class="overflow-x-scroll">
        <Toolbar class="mb-4">
          <template #start>
            <Button label="New" icon="pi pi-plus" severity="success" class="mr-2" />
            <Button label="Delete" icon="pi pi-trash" severity="danger" />
          </template>
          <template #end>
            <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="mr-2 inline-block" />
            <Button label="Export" icon="pi pi-upload" severity="help" />
          </template>
        </Toolbar>
        <DataTable :value="vendors" :tabStyle="{ 'min-width': '60rem' }" rowHover dataKey="id" paginator :rows="10" stripedRows>
          <Column style="min-width: 25rem" sortable>
            <template #header>
              <span class="font-semibold text-sm text-color-secondary">Name</span>
            </template>
            <!-- TODO: logo -->
            <template #body="{ data }">
              <div class="flex align-items-center gap-3">
                <!--                <img :src="'data:image/png;base64,' + data.images?.[0]?.data" class="max-w-10rem max-h-10rem" />-->
                <div>
                  <RouterLink :to="`/vendors/${data.id}`">
                  <p class="mt-0 mb-2 font-medium text-lg text-color-primary">{{ data.name }}</p>
                  </RouterLink>
                </div>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </section>
      </TabPanel>
      <TabPanel header="Individuals">
      </TabPanel>
    </TabView>
  </section>
</template>
