import { http } from '@/api'

export const PurchasesService = {
  getPurchaseOrderById(orderId) {
    const order = http.get(`/api/purchases/${orderId}`).then((result) => result)

    return order
  },
  async listPurchases() {
    return await http.get('/api/purchases')
  }

}
