import { http } from '@/api'

export const PartnershipService = {

  listVendors() {
    return this.listPartnerships("vendors")
  },
  listCustomers() {
    return this.listPartnerships("customers")
  },
  listTiers() {
    return http.get('/api/partnerships/tiers')
  },
  getCustomer(customerId) {
    return this.getPartnershipById("customers", customerId)
  },
  getVendor(vendorId) {
    return this.getPartnershipById("vendors", vendorId)
  },
  getPartnershipById(type, partnerId) {
    const partner = http.get(`/api/partnerships/${type}/${partnerId}`).then((result) => result)

    return partner
  },
  listPartnerships(type) {
    return http.get(`/api/partnerships/${type}`, {
      params: {
        page: 0,
        size: 40,
        sort: 'id',
        direction: 'DESC'
      }
    })
  },
}

export default {
  PartnerService: PartnershipService
}
