<script setup>
  import AppSidebar from './AppSidebar.vue'
  import { useLayout } from '@/layout/composables/layout'
  import AppToolbar from '@/layout/AppToolbar.vue'
  import AppFooter from "@/layout/AppFooter.vue";
  import {ref, watch} from "vue";
  const { layoutConfig, layoutState, isSidebarActive } = useLayout()
  const outsideClickListener = ref(null)

  watch(isSidebarActive, (newVal) => {
    if (newVal) {
      bindOutsideClickListener()
    } else {
      unbindOutsideClickListener()
    }
  })

  const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
      outsideClickListener.value = (event) => {
        if (isOutsideClicked(event)) {
          layoutState.overlayMenuActive.value = false
          layoutState.staticMenuMobileActive.value = false
          layoutState.menuHoverActive.value = false
        }
      }
      document.addEventListener('click', outsideClickListener.value)
    }
  }
  const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
      document.removeEventListener('click', outsideClickListener)
      outsideClickListener.value = null
    }
  }
  const isOutsideClicked = (event) => {
    const sidebarEl = document.querySelector('.layout-sidebar')
    const topbarEl = document.querySelector('.layout-menu-button')

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target))
  }
</script>

<template>
  <div class="min-h-screen flex relative lg:static surface-ground">
    <AppSidebar></AppSidebar>
    <div class="min-h-screen flex flex-column relative flex-auto">
      <AppToolbar></AppToolbar>
      <div class="p-1 flex flex-column flex-auto">
        <div class="surface-border border-round surface-section flex-auto">
          <DynamicDialog />
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
  <AppFooter/>

</template>
<style lang="scss" scoped></style>
