<script setup>
  import { ref } from 'vue'
  import { ProductService } from '@/service/productService.js'
  import Chip from "primevue/chip";

  const products = ref([])

  ProductService.listProducts().then((result) => {
    products.value = result.data.content
  })

  const uploadFile = async (event) => {
    console.log(`upload event triggered ${JSON.stringify(event)}`)
    const file = event.files[0];

    const body = new FormData();
    body.append("file", file);

    const uploadResult = ProductService.uploadProductCsv(body)
    console.log(`upload result ${uploadResult}`)
  }

</script>
<template>
  <section class="surface-overlay px-4 lg:px-6 py-4 px-4 lg:px-6 border-top-1 surface-border">
    <div class="border-bottom-1 surface-border">
      <h2 class="mt-0 mb-2 text-900 font-bold text-4xl">Products</h2>
      <p class="mt-0 mb-5 text-700 font-normal text-base">all your products and inventory</p>
    </div>
    <section class="flex flex-wrap gap-3 py-6 justify-content-between border-bottom-1 surface-border">
      <div class="overflow-x-scroll">
        <Toolbar class="mb-4">
          <template #start>
            <Button label="New" icon="pi pi-plus" severity="success" class="mr-2" />
            <Button label="Delete" icon="pi pi-trash" severity="danger" />
          </template>
          <template #end>
            <FileUpload name="file"  mode="basic" accept="text/csv"  label="Import"
                        @uploader="uploadFile" chooseLabel="Import" class="mr-2 inline-block"
                        :auto="true"
                        :custom-upload="true"
                        :with-credentials="true"
            />
            <Button label="Export" icon="pi pi-upload" severity="help" />
          </template>
        </Toolbar>
        <KeepAlive>
          <DataTable :value="products" :tabStyle="{ 'min-width': '60rem' }" rowHover dataKey="id" paginator :rows="10" stripedRows
                     v-if="$can('see', 'Products')">
            <Column style="min-width: 25rem" sortable>
              <template #header>
                <span class="font-semibold text-sm text-color-secondary">SKU</span>
              </template>
              <template #body="{ data }">
                <div class="flex align-items-center gap-3">
                  <Image :src="data.images?.[0]" alt="Image" width="100" preview />
                  <RouterLink :to="'/products/' + data.id">
                    <div>
                      <p class="mt-0 mb-2 font-medium text-lg text-color-primary">{{ data.sku }}</p>
                    </div>
                  </RouterLink>
                </div>
              </template>
            </Column>
            <Column style="min-width: 14rem" sortable>
              <template #header>
                <span class="font-semibold text-sm text-color-secondary">Title</span>
              </template>
              <template #body="{ data }">
                <RouterLink :to="'/products/' + data.id">
                  <p class="mt-0 mb-2 font-normal text-base text-color-secondary">{{ data.title }}</p>
                </RouterLink>
              </template>
            </Column>
            <Column sortable>
              <template #header>
                <span class="font-semibold text-sm text-color-secondary">Brand</span>
              </template>
              <template #body="{ data }">
                <RouterLink :to="'/products/' + data.id">
                  <p>{{ data.brand }}</p>
                </RouterLink>
              </template>
            </Column>
            <Column sortable>
              <template #header>
                <span class="font-semibold text-sm text-color-secondary">Price</span>
              </template>
              <template #body="{ data }">
                <RouterLink :to="'/products/' + data.id">
                  <p class="mt-0 mb-2 font-normal text-base text-color-secondary">{{ data.unitPrice.amount }} {{ data.unitPrice.currencyCode }}</p>
                </RouterLink>
              </template>
            </Column>
            <Column sortable>
              <template #header>
                <span class="font-semibold text-sm text-color-secondary">Availability</span>
              </template>
              <template #body="{ data }">
                <Tag :value="data.availability.totalQty + ' ' + data.availability.status" :severity="data.availability.totalQty > data.availability.minOnHandQty ? 'info' : 'severe'" />
              </template>
            </Column>
            <Column sortable>
              <template #header>
                <span class="font-semibold text-sm text-color-secondary">updated</span>
              </template>
              <template #body="{ data }">

                <Chip>{{ data.published }}</Chip>
              </template>
            </Column>
          </DataTable>
        </KeepAlive>
      </div>
    </section>
  </section>
</template>
