import { http } from '@/api'

export const FulfillmentService = {

  getFulfillmentOrderById(orderId) {
    const order = http.get('/api/fulfillment/orders/' + orderId).then((result) => result)

    return order
  },
  listFulfillmentOrders() {
    return http.get('/api/fulfillment/orders', {
      params: {
        page: 0,
        size: 40,
        sort: 'id',
        direction: 'DESC'
      }
    })
  }

}

export default {
  FulfillmentService
}
