<script setup>
  import { onMounted, ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { ProductService } from '@/service/productService.js'
  import Editor from 'primevue/editor'
  import Galleria from 'primevue/galleria'
  import { useHead } from '@unhead/vue'
  import DetailBar from "@/components/DetailBar.vue";

  const route = useRoute()
  const router = useRouter()
  const product = ref({})

  const editToggle = ref(true)

  useHead({
    title: () => product.value.title
  })

  const saveProduct = (product) => {
    ProductService.saveProduct(product)
        .then((result) => {
          product.value = result
          editToggle.value = !editToggle.value
        })
  }

  const deleteProduct = (product) => {
    ProductService.deleteProduct(product.id)
        .then(() => router.go(-1))

  }
  // TODO: breadcrumb
  onMounted(() => {
    const productId = route.params.productId

    ProductService.getProductById(productId).then((result) => {
      product.value = result.data
    })
  })
</script>

<template>
  <section class="surface-overlay px-4 lg:px-6 py-4 px-4 lg:px-6 border-top-1 surface-border">
    <div class="border-bottom-1 surface-border">
      <DetailBar :editToggle="editToggle" @edit="editToggle = !editToggle" @save="saveProduct(product)" @delete="deleteProduct(product)"/>
      <h2 class="mb-2 font-bold text-xl">{{ product.title }}</h2>
      <p class="mt-0 mb-5 text-700 font-normal text-base">{{ product.brand}}</p>
      <div class="surface-card p-4 shadow-2 border-round">
        <div class="grid formgrid p-fluid">
          <div class="field mb-4 col-12">
            <label for="title" class="font-medium">Title</label>
            <InputText id="title" type="text" v-model="product.title" :disabled="editToggle" />
          </div>
          <div class="field mb-4 col-12">
            <label for="sku" class="font-medium">SKU</label>
            <InputText id="sku" type="text" v-model="product.sku" :disabled="editToggle" />
          </div>
          <div class="field mb-4 col-12">
            <label for="description" class="font-medium">Description</label>
            <Editor id="description" v-model="product.description" :readonly="editToggle"></Editor>
          </div>
          <div class="field mb-4 col-12">
            <label for="tags" class="font-medium">Tags</label>
            <div v-for="tag in product.tags" :key="tag" id="tags" class="card flex flex-wrap gap-2">
              <Chip :label=tag  removable :disabled="editToggle" />
            </div>
          </div>

          <div class="field mb-4 col-12 md:col-6">
            <label for="avatar" class="font-medium">Images</label>
            <Galleria :value="product.images" :responsiveOptions="responsiveOptions" containerStyle="max-width: 640px" :disabled="editToggle">
              <template #item="slotProps">
                <Image :src="slotProps.item.url" width="400" preview v-tooltip="slotProps.item.caption" />
              </template>
              <template #thumbnail="slotProps">
                <Image :src="slotProps.item.url" width="100"/>
              </template>
            </Galleria>
            <div class="flex align-items-center">
              <img src="" class="mr-4" />
              <FileUpload mode="basic" name="avatar" url="./upload.php" accept="image/*" :maxFileSize="1000000" class="p-button-outlined p-button-plain" chooseLabel="Upload Image" :disabled="editToggle"></FileUpload>
            </div>
          </div>
          <div class="surface-100 mb-3 col-12" style="height: 2px"></div>
        </div>
      </div>
    </div>
  </section>
</template>
