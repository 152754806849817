<script setup>
const homeURL = import.meta.env.VITE_SITE_URL

</script>
<template>
  <header class="surface-overlay py-3 px-6 shadow-2 flex align-items-center justify-content-between relative" style="min-height: 80px">
    <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
      <a :href="homeURL" class="d-flex align-items-center ml-20">
        <img src="/images/logo-black.svg" alt="SupplyUnite logo">
      </a>
      <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
        <li class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
            <img src="/images/icon/icon_14.svg" alt="" class="lazy-img icon me-2">
            <a href="mailto:hello@supplyunite.com" class="fw-500">hello@supplyunite.com</a>
        </li>
        <li class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 hidden font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
            <img src="/images/icon/icon_15.svg" alt="" class="lazy-img icon me-2">
            <a href="tel:+1-216-999-4108" class="fw-500">+1-216-999-4108</a>
          </li>
      </ul>
    </div>

  </header>
  <div class="main-page-wrapper">
    <main>

      <router-view />
    </main>
  </div>

</template>

<style lang="scss" scoped>
header {
  box-sizing: border-box;
  line-height: 30px;
  display: block;
  color: rgb(25, 53, 45) !important;
}

a:link, a:visited {
  text-decoration-color: green !important;
  text-decoration: none;
  color: rgb(25, 53, 45) !important;


}
body {
  font-family: 'Satoshi' !important;
  font-size: 20px;
  font-weight: 400;

  background-color: #EBF3EE !important;
  --primary-color: EBF3EE !important;
}
//$light-bg-one: #EBF3EE;
//$light-bg-two: #EDF1EE;
//$cyan: #;

</style>
