<script setup>
  import { ref } from 'vue'
  import { ShopService } from '@/service/shopService.js'
  import Tag from "primevue/tag";

  const shops = ref({})
  const paymentMethods = ref([])

  const statusStyle = ref({
    Live: {icon: "pi pi-check", severity: "success"},
    Private: {icon: "pi pi-lock", severity: "warning"},
    OffLine: {icon: "pi pi-times", severity: "danger"}
  })

  ShopService.listShops()
  .then((res) => {
    shops.value = res
  })

  ShopService.listPaymentMethods()
    .then((res) => {
      paymentMethods.value = res
    })
</script>

<template>
  <section class="surface-overlay px-4 lg:px-6 py-4 px-4 lg:px-6 border-top-1 surface-border">
    <div class="border-bottom-1 surface-border">
      <h2 class="mt-0 mb-2 text-900 font-bold text-4xl">Shops</h2>
      <p class="mt-0 mb-5 text-700 font-normal text-base">your digital channels</p>
    </div>
    <section class="flex flex-wrap gap-3 py-6 justify-content-between border-bottom-1 surface-border">
      <div class="overflow-x-scroll">
        <div class="surface-ground">
          <div v-for="shop in shops" :key="shop.id" class="grid">
            <div class="col-12">
              <div class="surface-card shadow-2 border-round p-4">
                <div class="flex border-bottom-1 surface-border pb-4">
                  <img :src="shop.logo" style="width: 70px; height: 70px" class="mr-3" />
                  <div class="flex flex-column align-items-start">
                    <span class="text-xl text-900 font-medium mb-1">
                      {{ shop.name }}
                      <Tag :icon="statusStyle[shop.status]?.icon"
                           :severity="statusStyle[shop.status]?.severity"
                            :value="shop.status">
                     </Tag>

                    </span>

                    <span class="text-600 font-medium mb-2">{{ shop.description }}</span>
                    <span class="text-600 font-medium mb-2">
                      <a :href="`https://${shop.url}`" target="_blank" rel="noopener">
                        {{ shop.url }}
                        <span class="fa fa-link"></span>
                      </a>
                    </span>
                    <span class="text-600 font-medium mb-2">Type {{ shop.type }}</span>
                    <span class="text-600 font-medium mb-2">Language {{ shop.language }}</span>
                    <span class="text-600 font-medium mb-2">Payment Methods
                      <Chip v-for="pm in paymentMethods" :key="pm.id" :label="pm.name"></Chip>
                    </span>
                    <span class="bg-blue-50 text-blue-400 border-round inline-flex py-1 px-2 text-sm">{{shop.offerCount}} offers</span>
                    <RouterLink :to="'/shops/' + shop.id"><Button>Design</Button></RouterLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>
