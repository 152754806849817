<script setup>

import {useRouter} from "vue-router";
import {onMounted, ref} from "vue";
import {TaskService} from "@/service/taskService.js";
import {FormKit} from "@formkit/vue";
import {usePartyInfoStore} from "@/stores/party.store.js";

const router = useRouter()

const {module, processKey} = defineProps(['module', 'processKey']);

const process = ref(null)


const schema = ref([])
const data = ref(null)
const taskId = ref(null)



onMounted(() => {
  TaskService.getProcessStartForm(processKey, "orders")
      .then((result) => {
        schema.value = result.data.schema.elements
        data.value = usePartyInfoStore().partyInfo
      })
})


</script>

<template>
  <div class="surface-ground">
    <div class="surface-card p-5 shadow-2">
      <Button icon="pi pi-arrow-left" outlined @click="router.go(-1)" />
      <div class="flex flex-column lg:flex-row">
        <div class="flex flex-auto flex-column md:flex-row">
          <div class="flex-auto md:pl-5 lg:pr-5 pt-5 md:pt-0 border-right-none lg:border-right-1 surface-border">
            <h2>{{module}}/{{process}}</h2>

            <div class="surface-ground">
              <div class="surface-card p-5 shadow-2">
                <div class="flex flex-column lg:flex-row">
                  <div class="flex flex-auto flex-column md:flex-row">
                    <FormKit
                        id="form"
                        v-model="data"
                        type="form"
                        :submit-attrs="{
              inputClass: 'p-button p-component',
            }"
                        @submit="submitHandler(data)"
                    >
                      <FormKitSchema :schema="schema" :data="data"/></FormKit>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">

</style>
