import { defineStore } from 'pinia'
import { http } from '@/api'
import {useLocalStorage} from "@vueuse/core";

export const usePartyInfoStore = defineStore('partyInfo', {
    persist: true,
    state: () => ({
        partyInfo: useLocalStorage('partyInfo', {
            name: '',
            team: [],
            businessKey: '',
            preferences: {}
            })
    }),
    actions: {
        async getPartyInfo() {
            await http.get('/api/parties/profile').then((result) => {
                console.log(`setting partyInfo to ${result.data}`)
                this.$state.partyInfo = result.data
                localStorage.setItem('partyInfo', JSON.stringify(this.$state.partyInfo))

            })

            return this.$state.partyInfo
        }
    }
})
