<script setup>
  import { ref } from 'vue'
  import { useAuthStore } from '@/stores/auth.store.js'

  const homeURL = import.meta.env.VITE_SITE_URL

  const authStore = useAuthStore()

  const email = ref('')
  const password = ref('')
  const companyName = ref('')
  const acceptTerms = ref(true)

  function register() {
    let registerationForm = { companyName: companyName.value, email: email.value, password: password.value }
    let result = authStore.signUp(registerationForm)
  }
</script>


<template>
  <div class="px-4 py-8 md:px-6 lg:px-8">
    <div class="flex flex-wrap align-items-center justify-content-center">
      <form class="w-full lg:w-6 p-4 lg:p-7 surface-card">
        <h1 class="mb-100">Welcome to SupplyUnite</h1>
        <fieldset>
          <label for="email" class="block text-900 font-medium mb-2">Email</label>
          <InputText id="email" v-model="email" type="text" placeholder="Email address" class="w-full mb-4"
                     autocomplete="email"/>

          <label for="password" class="block text-900 font-medium mb-2">Password</label>
          <InputText id="password" v-model="password" type="password" placeholder="Password" class="w-full mb-4"
                     autocomplete="current-password"/>

          <label for="companyName" class="block text-900 font-medium mb-2">Company Name</label>
          <InputText id="companyName" v-model="companyName" type="text" class="w-full mb-4" autocomplete="organization" />

          <div class="flex align-items-center justify-content-between mb-6">
            <div class="flex align-items-center">
              <Checkbox id="acceptTerms" :binary="true" v-model="acceptTerms" class="mr-2"></Checkbox>
              <label for="acceptTerms">Accept our  <a :href="homeURL + 'policies/terms-and-conditions/'" target="_blank" class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Terms & Conditions</a></label>
            </div>
          </div>

          <Button @click="register" label="Create Account" icon="pi pi-user"></Button>
        </fieldset>

        <Divider align="center" class="my-6">
          <span class="text-600 font-normal text-sm">OR</span>
        </Divider>
        <div class="mt-6 text-center text-600">
          Already have an account?
          <router-link to="/login" tabindex="0" class="font-medium text-cyan-300">
            <Button>Login</Button>
          </router-link>
        </div>


      </form>
    </div>
  </div>
</template>
