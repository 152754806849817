<script setup>

import {onMounted, ref} from "vue";
import {PartyService} from "@/service/partyService.js";
import {formatTimeAgo} from "@vueuse/core";
import {TaskService} from "@/service/taskService.js";
import {usePartyInfoStore} from "@/stores/party.store.js";
import {useDialog} from "primevue/usedialog";
import taskForm from "@/components/TaskForm.vue";
import TaskForm from "@/components/TaskForm.vue";
import {FormKit} from "@formkit/vue";

const BUSINESS_KEY = ref(usePartyInfoStore().partyInfo.businessKey)
const PROCESS_KEY = 'enlistNewUser'

const taskDialog = useDialog()


const members = ref([])
const roles = ref([])

const schema = ref([])
const data = ref(null)
const taskId = ref(null)
const visible = ref(false)
onMounted(() => {
  PartyService.listMembers().then((result) => members.value = result.data)
  PartyService.listRoles().then((result) => roles.value = result.data)

  TaskService.getProcessStartForm(PROCESS_KEY, BUSINESS_KEY.value)
      .then((result) => {
        schema.value = result.data.schema.elements
        data.value = usePartyInfoStore().partyInfo
      })
})

const showAddMemberForm = (eventData) => {
  visible.value = !visible.value
  console.log(eventData)
}

const addNewMember = (eventData) => {
  TaskService.startUserProcess(PROCESS_KEY, BUSINESS_KEY.value, eventData)
      .then ((result) => {
        PartyService.listMembers().then((result) => members.value = result.data)
        visible.value = !visible.value
      })


}
</script>

<template>
  <section class="surface-overlay px-4 lg:px-6 py-4 px-4 lg:px-6 border-top-1 surface-border">
    <div class="border-bottom-1 surface-border">
      <h2 class="mt-0 mb-2 text-900 font-bold text-4xl">Members & Roles</h2>
      <p class="mt-0 mb-5 text-700 font-normal text-base">manage your team on this page</p>
    </div>
    <section class="flex flex-wrap gap-3 py-6 justify-content-between border-bottom-1 surface-border">
      <div class="flex-shrink-0 w-15rem">
        <h3 class="mb-4 mt-0 text-900 font-medium text-xl">Members</h3>
        <p class="mb-4 mt-0 text-700 font-normal text-base">Manage your member in this project</p>
        <Button class="w-auto" @click="showAddMemberForm">Add Member</Button>

      </div>
      <div class="overflow-x-scroll">
        <DataTable :value="members" :tabStyle="{'min-width': '60rem'}" rowHover>
          <Column style="min-width:25rem">
            <template #header>
              <span class="font-semibold text-sm text-color-secondary">Name</span>
            </template>
            <template #body="{data}">
              <div class="flex align-items-center gap-3">
                <Avatar :image="'data:image/png;base64, ' + data.avatar" styleClass="mr-2" size="large" shape="circle"></Avatar>
                <div>
                  <p class="mt-0 mb-2 font-medium text-lg text-color-primary">{{data.email}}</p>
                  <p class="mt-0 mb-2 font-normal text-base text-color-secondary">{{data.firstName}} {{data.lastName}}</p>
                </div>
              </div>
            </template>
          </Column>
          <Column style="min-width:14rem">
            <template #header>
              <span class="font-semibold text-sm text-color-secondary">Position</span>
            </template>
            <template #body="{data}">
              <p class="mt-0 mb-2 font-normal text-base text-color-secondary">{{data.position}} <Chip>{{data.role}}</Chip></p>
            </template>
          </Column>
          <Column style="min-width:13rem" sortable>
            <template #header>
              <span class="font-semibold text-sm text-color-secondary">Last Login</span>
            </template>
            <template #body="{data}">
              <p class="mt-0 mb-2 font-normal text-base text-color-secondary">{{formatTimeAgo(data.lastLoginMillis)}}</p>
            </template>
          </Column>
          <Column style="min-width:8rem">
            <template #body>
              <Button type="button" icon="pi pi-ellipsis-v" class="p-button-text p-button-secondary" @click="$refs.menu.toggle($event)"></Button>
              <Menu ref="menu" appendTo="body" popup :model="items"></Menu>
            </template>
          </Column>
        </DataTable>
      </div>
    </section>
    <section class="flex flex-wrap gap-3 py-6 justify-content-between border-bottom-1 surface-border">
      <div class="flex-shrink-0 w-15rem">
        <h3 class="mb-4 mt-0 text-900 font-medium text-xl">Roles</h3>
        <p class="mb-4 mt-0 text-700 font-normal text-base">security roles</p>
        <Button label="Add a role" class="w-auto"></Button>
      </div>
      <div class="overflow-x-scroll">
        <DataTable :value="roles" rowHover>
          <Column style="min-width:25rem">
            <template #header>
              <span class="font-semibold text-sm text-color-secondary">Role Name</span>
            </template>
            <template #body="{data}">
              <div class="flex align-items-center">
                <span class="border-circle mr-2" :class="data.color" style="width: 7px; height: 7px;"></span>
                <p class="mt-0 mb-0 font-medium text-lg text-color-primary">{{data.name}}</p>
              </div>
            </template>
          </Column>
          <Column style="min-width:14rem">
            <template #header>
              <span class="font-semibold text-sm text-color-secondary">Description</span>
            </template>
            <template #body="{data}">
              <p class="mt-0 mb-0 font-normal text-base text-color-secondary">{{data.description}}</p>
            </template>
          </Column>
          <Column style="min-width:13rem">
            <template #header>
              <span class="font-semibold text-sm text-color-secondary">Users</span>
            </template>
            <template #body="{data}">
              <p class="mt-0 mb-0 font-normal text-base text-color-secondary">{{data.users}}</p>
            </template>
          </Column>
          <Column style="min-width:8rem">
            <template #body>
              <Button type="button" icon="pi pi-ellipsis-v" class="p-button-text p-button-secondary" @click="$refs.menu.toggle($event)"></Button>
              <Menu ref="menu" appendTo="body" popup :model="items"></Menu>
            </template>
          </Column>
        </DataTable>
      </div>
    </section>
  </section>

  <Dialog v-model:visible="visible" modal header="Edit Profile" :style="{ width: '25rem' }">
    <FormKit
        id="form"
        v-model="data"
        type="form"
        :submit-attrs="{
              inputClass: 'p-button p-component',
            }"
        @submit="addNewMember(data)"
    >
      <FormKitSchema :schema="schema" :data="data"/></FormKit>

  </Dialog>

</template>

<style scoped lang="scss">

</style>
