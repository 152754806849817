<script setup>
  import { onMounted, ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { PartnershipService } from '@/service/partnershipService.js'
  import PartyAvatar from "@/components/PartyAvatar.vue";

  const route = useRoute()
  const router = useRouter()
  const customer = ref({})

    const customerId = route.params.customerId

    PartnershipService.getCustomer(customerId).then((result) => (customer.value = result.data))
</script>

<template>
  <section class="surface-overlay px-4 lg:px-6 py-4 px-4 lg:px-6 border-top-1 surface-border">
    <div class="border-bottom-1 surface-border">
      <Button icon="pi pi-arrow-left" outlined @click="router.go(-1)" />
      <h2 class="mt-0 mb-2 text-900 font-bold text-4xl">
        {{ customer.buyerParty.name }}
        <Badge size="large" class="">{{ customer.buyerTier.name }}</Badge>
        <Badge size="large" class="ml-2 pl-2">{{ customer.orderCount }} orders</Badge>
      </h2>
      <p class="mt-0 mb-5 text-700 font-normal text-base">{{ customer.buyerParty.name }}
        {{ customer.buyerParty.baseTerritory }}</p>

      <PartyAvatar :part="customer.buyerParty" :person="customer.buyerAgent" />

      <PartyAvatar v-if="customer.sellerAgent" :person="customer.sellerAgent" />
      <div class="surface-card p-4 shadow-2 border-round">
        <div class="grid formgrid p-fluid">
          <div class="field mb-4 col-6">
            <label for="nickname" class="font-medium">Title</label>
            <InputText id="nickname" type="text" v-model="customer.title" />
          </div>
          <div class="field mb-4 col-6">
            <label for="nickname" class="font-medium">SKU</label>
            <InputText id="nickname" type="text" v-model="customer.sku" />
          </div>
          <div class="field mb-4 col-12">
            <label for="bio" class="font-medium">Description</label>
            <Editor v-model="customer.description"></Editor>
          </div>
          <div class="surface-100 mb-3 col-12" style="height: 2px"></div>
          <div class="field mb-4 col-12 md:col-6">
            <label for="email" class="font-medium">Email</label>
            <InputText id="email" />
          </div>
          <div class="field mb-4 col-12 md:col-6">
            <label for="bio" class="font-medium">Country</label>
            <Dropdown v-model="selectedCountry" :options="countries" optionLabel="name" :filter="true" filterBy="name" :showClear="true" placeholder="Select a Country">
              <template #option="slotProps">
                <div class="flex align-items-center">
                  <img src="" :class="'mr-2 flag flag-' + slotProps.option.code.toLowerCase()" style="width: 18px" />
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="field mb-4 col-12 md:col-6">
            <label for="city" class="font-medium">City</label>
            <InputText id="city" type="text" />
          </div>
          <div class="field mb-4 col-12 md:col-6">
            <label for="state" class="font-medium">State</label>
            <InputText id="state" type="text" />
          </div>
          <div class="surface-100 mb-3 col-12" style="height: 2px"></div>
          <div class="field mb-4 col-12">
            <label for="website" class="font-medium">Website</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">www</span>
              <InputText id="website" type="text" />
            </div>
          </div>
          <div class="surface-100 mb-3 col-12" style="height: 2px"></div>
          <div class="field mb-4 col-12">
            <label for="privacy" class="font-medium">Privacy</label>
            <div class="flex align-items-center">
              <InputSwitch v-model="checked"></InputSwitch>
              <span class="ml-2">Share my data with contacts</span>
            </div>
          </div>
          <div class="surface-100 mb-3 col-12" style="height: 2px"></div>
          <div class="col-12">
            <Button label="Save Changes" class="w-auto mt-3"></Button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
