<script setup>
import {onMounted, ref} from "vue";
import {FormKit} from "@formkit/vue";
import {TaskService} from "@/service/taskService.js";
import {usePartyInfoStore} from "@/stores/party.store.js";
import TeamList from "@/components/Party/TeamList.vue";
import DetailBar from "@/components/DetailBar.vue";

const BUSINESS_KEY = ref(usePartyInfoStore().partyInfo.businessKey)
const PROCESS_KEY = 'partyInfo'

const schema = ref([])
const data = ref(null)
const taskId = ref(null)

const editToggle = ref(true)

onMounted(() => {
  TaskService.getProcessStartForm(PROCESS_KEY, BUSINESS_KEY.value)
      .then((result) => {
        schema.value = result.data.schema.elements
        data.value = usePartyInfoStore().partyInfo
      })
})

const submitHandler = (data) => {
  TaskService.startUserProcess(PROCESS_KEY, BUSINESS_KEY.value, data)
      .then((result) => taskId.value = result.data)
}


</script>

<template>
  <TabView>
    <TabPanel header="Company">
      <DetailBar :editToggle="editToggle" @edit="editToggle = !editToggle" @save="saveProduct(product)" noDelete/>

      <section class="surface-overlay px-4 lg:px-6 py-4 px-4 lg:px-6 border-top-1 surface-border">
    <div class="border-bottom-1 surface-border">
      <h2 class="mt-0 mb-2 text-900 font-bold text-4xl">Company Profile</h2>
      <p class="mt-0 mb-5 text-700 font-normal text-base">your company and team information</p>
    </div>
    <div class="surface-ground">
      <div class="surface-card p-5 shadow-2">
        <div class="flex flex-column lg:flex-row">
          <div class="flex flex-auto flex-column md:flex-row">
            <FormKit
                id="form"
                v-model="data"
                type="form"
                :disabled="editToggle"
                :submit-attrs="{
              inputClass: 'p-button p-component',
            }"
                @submit="editToggle && submitHandler(data)"
            >
              <FormKitSchema :schema="schema" :data="data"/></FormKit>
          </div>
        </div>
      </div>
    </div>
  </section>
    </TabPanel>
    <TabPanel header="Team">
      <TeamList />
    </TabPanel>
  </TabView>
</template>

<style scoped lang="scss">

</style>
