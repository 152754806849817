import { http } from '@/api'

export const SearchService = {
    searchProducts(term) {
        console.log(`Searching for ${term}`)
        return http.get('/api/products/search', {params: {q: term}}).then((result) => result.data)
    }
}

export default {
    SearchService
}
