import { http } from '@/api'

export const SalesService = {
  getOrderById(orderId) {
    const order = http.get('/api/sales/' + orderId).then((result) => result)

    return order
  },
  async listSales() {
    return await http.get('/api/sales', {
      params: {
        page: 0,
        size: 40,
        sort: 'id',
        direction: 'DESC'
      }
    })
  }

}
