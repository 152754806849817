<script setup>

import {UserService} from "@/service/userService.js";
import {ref} from "vue";

const notifications = ref([])

UserService.getLatestNotifications()
  .then((result) => notifications.value = result)
</script>
<template>

  <span v-for="notification in notifications" :key="notification.id">
    <Message :severity="notification.level">{{notification.message}}</Message>
  </span>
{{notifications}}

</template>
