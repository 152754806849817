<template>
  {{invoice}}
<!--  <v-layout>-->
<!--    <v-flex>-->
<!--      <v-flex absolute right>-->
<!--        <v-btn v-if="invoice.status !== 'PAID'" color="primary" @click.stop="addPaymentDialog = true">-->
<!--          <v-icon>add</v-icon>-->
<!--          Enter Payment-->
<!--        </v-btn>-->
<!--      </v-flex>-->

<!--      <h1>Invoice #{{ invoice.sequence }} for order #{{ invoice.order.sequence }} {{ invoice.buyerParty.name }}</h1>-->
<!--      <v-card>-->
<!--        <v-card-text>-->
<!--          <v-text-field label="invoice status" v-model="invoice.status" readonly></v-text-field>-->
<!--          <v-switch @change="saveInvoice" label="allow partial payments?" color="primary"-->
<!--                    v-model="invoice.isSplitable"></v-switch>-->
<!--          <v-text-field label="payment status" v-model="invoice.paymentStatus" readonly></v-text-field>-->
<!--          <v-text-field label="delivery status" v-model="invoice.deliveryStatus" readonly></v-text-field>-->
<!--          <v-text-field @change="saveInvoice" label="internal reference #" v-model="invoice.sellerRef"></v-text-field>-->
<!--          <v-text-field label="amount invoiced" v-model="invoice.netAmount" readonly></v-text-field>-->
<!--          <v-text-field label="issued on" readonly-->
<!--                        :value="invoice.issueDateMillis | moment('YYYY-MM-DD')"></v-text-field>-->
<!--          <v-text-field @change="saveInvoice" label="assigned to" v-model="invoice.sellerAgent"></v-text-field>-->
<!--        </v-card-text>-->
<!--      </v-card>-->

<!--      <v-card>-->
<!--        <v-card-title><h3>Buyer party</h3></v-card-title>-->
<!--        <v-card-text>-->
<!--          <v-text-field label="customer" v-model="invoice.buyerParty.businessName" readonly></v-text-field>-->
<!--          <v-text-field v-if="invoice.buyerAgent.firstName != null || invoice.buyerAgent.lastName != null"-->
<!--                        label="placed by" :value="invoice.buyerAgent.firstName + ' ' + invoice.buyerAgent.lastName"-->
<!--                        disabled readonly></v-text-field>-->
<!--          <v-text-field label="email" v-model="invoice.buyerAgent.email"></v-text-field>-->
<!--          <v-text-field label="position" v-model="invoice.buyerAgent.position"></v-text-field>-->
<!--          <v-text-field label="customer ref#" v-model="invoice.buyerRef"></v-text-field>-->
<!--        </v-card-text>-->
<!--      </v-card>-->

<!--      <v-card>-->
<!--        <v-card-title><h3>Payment</h3></v-card-title>-->
<!--        <v-card-text>-->
<!--          <v-text-field label="amount invoiced" v-model="invoice.netAmount"></v-text-field>-->
<!--          <v-text-field label="amount paid" v-model="invoice.payment.amountPaid"></v-text-field>-->
<!--          <v-text-field label="amount remaining" v-model="invoice.payment.amountRemaining"></v-text-field>-->
<!--        </v-card-text>-->

<!--      </v-card>-->

<!--      <v-card>-->
<!--        <v-card-title><h3>Delivery</h3></v-card-title>-->
<!--        <address-form v-if="invoice.deliveryAddress != null"-->
<!--                      label="Delivery address" :address="invoice.deliveryAddress" readonly></address-form>-->
<!--      </v-card>-->
<!--      &lt;!&ndash; table class="table">-->
<!--        <thead>-->
<!--          <td>Product</td>-->
<!--          <td>Title</td>-->
<!--          <td>Variant</td>-->
<!--          <td>Quantity</td>-->
<!--          <td>Availability</td>-->
<!--          <td>Status</td>-->
<!--        </thead>-->
<!--        <tbody>-->
<!--          <tr v-for="item in invoice.items" :key="item.id">-->
<!--            <td @click="rowSelected(item.productId)"><v-avatar tile size="96"><img :src="'data:image/png;base64,' + item.image" /></v-avatar></td>-->
<!--            <td>{{item.productTitle}}</td>-->
<!--            <td>-->
<!--              <span v-for="attr in item.attributes" :key="attr.key">{{ attr.key + ': ' + attr.val + ' '}}</span>-->
<!--            </td>-->
<!--            <td>{{ item.quantity }}</td>-->
<!--            <td>{{ item.availability }}</td>-->
<!--            <td>-->
<!--              <v-btn v-for="next in item.nextStatuses" :key="next"-->
<!--                @click.native="changeItemOrderStatus(item, next)">{{next}}</v-btn>-->
<!--            </td>-->
<!--          </tr>-->
<!--        </tbody>-->
<!--      </table &ndash;&gt;-->


<!--      <v-dialog v-model="addPaymentDialog" fullscreen transition="dialog-bottom-transition" :overlay="false">-->
<!--        <v-card>-->
<!--          <v-toolbar dark>-->
<!--            <v-btn icon @click.native="closeAddPaymentDialog" dark>-->
<!--              <v-icon>close</v-icon>-->
<!--            </v-btn>-->
<!--            <v-toolbar-title>Add Payment</v-toolbar-title>-->
<!--            <v-spacer></v-spacer>-->
<!--            <v-toolbar-items>-->
<!--              <v-btn dark flat @click.native="addPayment(newPayment)">Save</v-btn>-->
<!--            </v-toolbar-items>-->
<!--          </v-toolbar>-->
<!--          <v-card-text>-->
<!--            <v-flex>-->
<!--              <v-select label="payment method" required :items="paymentMethods"-->
<!--                        v-model="newPayment.paymentMethodId"-->
<!--                        item-text="name" item-value="id" hint="payment method used"></v-select>-->
<!--              <v-text-field label="amount" v-model="newPayment.amount" :value="invoice.amountRemaining"></v-text-field>-->
<!--              <v-text-field label="currency" v-model="newPayment.currencyCode" :value="invoice.currency"></v-text-field>-->
<!--              <v-select label="authorized by" required :items="buyerTeam" autocomplete-->
<!--                        v-model="newPayment.buyerAuthorizingPersonId" :value="invoice.buyerAgent"-->
<!--                        item-text="email" item-value="id" hint="who in the buyer team authorized this?"></v-select>-->

<!--            </v-flex>-->
<!--          </v-card-text>-->
<!--          <v-card-actions>-->
<!--            <v-btn flat @click.stop="closeAddPaymentDialog">Cancel</v-btn>-->
<!--            <v-btn color="primary" flat @click.stop="addPayment(newPayment)">Save</v-btn>-->

<!--          </v-card-actions>-->
<!--        </v-card>-->
<!--      </v-dialog>-->

<!--      &lt;!&ndash; v-dialog v-model="invoice.isSplitable" fullscreen transition="dialog-bottom-transition" :overlay="false">-->
<!--        <v-card>-->
<!--          <v-toolbar dark>-->
<!--          <v-btn icon @click.native="invoice.isSplitable = false" dark>-->
<!--            <v-icon>close</v-icon>-->
<!--          </v-btn>-->
<!--          <v-toolbar-title>Allow for the invoice to be split?</v-toolbar-title>-->
<!--          <v-spacer></v-spacer>-->
<!--          <v-toolbar-items>-->
<!--            <v-btn dark flat @click.native="invoice.isSplitable = true">Save</v-btn>-->
<!--          </v-toolbar-items>-->
<!--        </v-toolbar>-->
<!--          <v-card-text>-->
<!--            <v-flex>-->
<!--            <v-alert type="info" v-show="true">-->
<!--              <p>Let the buyer to pay the amount due in installements</p>-->
<!--            </v-alert>-->

<!--            </v-flex>-->
<!--          </v-card-text>-->
<!--          <v-card-actions>-->
<!--            <v-btn flat @click.stop="invoice.isSplitable = false">Cancel</v-btn>-->
<!--            <v-btn color="primary" flat @click.stop="invoice.isSplitable = true">Save</v-btn>-->

<!--          </v-card-actions>-->
<!--        </v-card>-->
<!--      </v-dialog &ndash;&gt;-->


<!--    </v-flex>-->
<!--  </v-layout>-->
</template>

<script setup>

import {onMounted, ref} from "vue";
import BillingService from "@/service/billingService.js";

const {invoiceId} = defineProps(['invoiceId'])
const invoice = ref({})

onMounted (() => {
  BillingService.fetchInvoice(invoiceId)
      .then ((result) => invoice.value = result)
//  this.fetchPaymentMethods()

})


//     fetchInvoice: function (id) {
//       AXIOS.get(`/api/invoices/${id}`)
//         .then(response => {
//           this.invoice = response.data
//           this.fetchTeamMembers(this.invoice.buyerParty.orgId)
//         })
//     },
//     fetchPaymentMethods: function () {
//       AXIOS.get('/api/payment-methods')
//         .then(response => {
//           this.paymentMethods = response.data
//         })
//     },
//     fetchTeamMembers: function (partyId) {
//       AXIOS.get(`/api/parties/${partyId}/team`)
//         .then(response => {
//           this.buyerTeam = response.data
//         })
//     },
//     saveInvoice: function () {
//       const updated = this.invoice
//       delete updated.order
//       AXIOS.patch(`/api/invoices/${this.invoiceId}`, this.invoice)
//         .then(response => {
//           this.invoice = response.data
//           this.$store.dispatch('setNotification', {color: 'success', show: true, text: 'Saved'})
//         })
//     },
//     closeAddPaymentDialog: function () {
// //        this.newPayment = {}
//       this.addPaymentDialog = false
//     },
//     addPayment: function (payment) {
//       payment.invoiceId = this.invoiceId
//       payment.buyerAuthorizingPerson = this.invoice.buyerAgent
//       AXIOS.post(`/api/invoices/${this.invoiceId}/pay`, payment)
//         .then(response => {
//           this.invoice.payment = response.data
//           this.$store.dispatch('setNotification', {color: 'success', show: true, text: 'Payment registered'})
//           this.newPayment = {}
//         })
//
//       this.closeAddPaymentDialog()
//     }
//   }
// }
</script>

