import axios from 'axios'
import { useAuthStore } from '@/stores/auth.store.js'
import {buildWebStorage, setupCache} from "axios-cache-interceptor";

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 5000,
  headers: ['Location'],

})
const http = setupCache(axiosInstance, {storage: buildWebStorage(localStorage, 'axios-cache:') })

http.interceptors.request.use((config) => {
  const authStore = useAuthStore()

  const token = authStore.jwt
  config.headers.Authorization = `Bearer ${token}`
  return config
})

http.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response && [301, 302].includes(error.response.status)) {
      const redirectUrl = error.response.headers.location;
      return http.get(redirectUrl);
    }
    console.log(`api error ${JSON.stringify(error)}`)
    if (error.response.status === 401) {
      useAuthStore().signOut()
      Promise.reject(error)
    }
    Promise.reject(error)
  }
)

export { http, axios }
