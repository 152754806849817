<script setup>
import {ref} from 'vue'
import {useAuthStore} from '@/stores/auth.store.js'
import {useRoute} from 'vue-router'

const authStore = useAuthStore()
const route = useRoute()

const email = ref(null)
const password = ref(null)
const rememberMe = ref(true)

function login() {
  authStore.signIn(email.value, password.value, route.query.redirect)
}
</script>

<template>
  <div class="px-4 py-8 md:px-6 lg:px-8">
    <div class="flex flex-wrap align-items-center justify-content-center">
      <form class="w-full lg:w-6 p-4 lg:p-7 surface-card">
        <h1>Login</h1>
        <fieldset>
          <label for="email" class="block text-900 font-medium mb-2">Email</label>
          <InputText id="email" v-model="email" type="text" placeholder="Email address" class="w-full mb-4"
                     autocomplete="email"/>

          <label for="password" class="block text-900 font-medium mb-2">Password</label>
          <InputText id="password" v-model="password" type="password" placeholder="Password" class="w-full mb-4"
                     autocomplete="current-password"/>

          <div class="flex align-items-center justify-content-between mb-6">
            <div class="flex align-items-center">
              <Checkbox id="rememberme" :binary="true" v-model="rememberMe" class="mr-2"></Checkbox>
              <label for="rememberme">Remember me</label>
            </div>
            <router-link to="/reset-password" class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Forgot password?</router-link>
          </div>

          <Button @click="login" label="Login" icon="pi pi-user"></Button>
        </fieldset>

        <Divider align="center" class="my-6">
          <span class="text-600 font-normal text-sm">OR</span>
        </Divider>
        <div class="mt-6 text-center text-600">
          Don't have an account?
          <router-link to="/register" tabindex="0" class="font-medium text-cyan-300">
            <Button>Create Account</Button>
          </router-link>
        </div>


      </form>
    </div>
  </div>
</template>
